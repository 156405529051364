import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Layout from '../../product/components/templates/Layout'
import '../Dashboard/Dashboard.css'
import CustomTable from '../../product/components/molecules/CustomTable'
import CustomSearch from '../../product/components/molecules/CustomSearch'
import TextField from '@material-ui/core/TextField'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../product/components/atoms/SecondaryButton'

import { useFormik } from 'formik'
import AlertContext from '../../product/context/alert/alertContext'
import ProductContext from '../../product/context/product/productContext'
import AuctionContext from '../../product/context/auction/auctionContext'
import UserContext from '../../product/context/user/userContext'
import CommonContext from '../../product/context/common/commonContext'
import ReportContext from '../../product/context/report/reportContext'
import { mapData } from '../../product/common/components'
import moment from 'moment'

import CustomDialog from '../../product/components/organisms/Dialog'
import SingleListing from './SingleListing'
import { Button } from '@material-ui/core'
import BulkListing from './BulkListing'
import Loaders from '../../product/components/molecules/Loaders'
import { dateTimeFormatFunction } from '../../custom/common/components'

const Listing = (props) => {
    const alertContext = useContext(AlertContext)
    const productContext = useContext(ProductContext)
    const auctionContext = useContext(AuctionContext)
    const { getAllUsers } = useContext(UserContext)
    const reportContext = useContext(ReportContext)
    const commonContext = useContext(CommonContext)
    const [isLoading, setIsLoading] = useState(true)
    const { setBidHistoryValue } = commonContext
    const { getAllExportDownload } = reportContext
    const [manage, setManage] = useState({
        popup: false,
        status: 'new',
    })
    const [bulkPopup, setBulkPopup] = useState(false)

    const toggleFullScreenPopup = (popup, status, id) => {
        if (popup) {
            setManage({ popup, status, id })
        } else {
            setManage({ popup, status: 'new', id: 0 })
        }
    }

    const toggleBulkPopup = (popup) => {
        setBulkPopup({ popup })
    }

    const history = useHistory()
    const { setAlert } = alertContext
    const {
        all_products,
        activelots,
        getAllProducts,
        changeProductStatus,
        responseStatus: responseStatusProduct,
        clearResponse: clearResponseProduct,
    } = productContext
    const {
        assignAuctionLot,
        responseStatus: responseStatusAuction,
        clearResponse: clearResponseAuction,
    } = auctionContext
    const [assignAuction, setAssignAuction] = React.useState(false)
    const [changeStatus, setChangeStatus] = React.useState(false)
    const changeAssignAuction = () => {
        setAssignAuction(!assignAuction)
    }
    const changeChangeStatus = () => {
        setChangeStatus(!changeStatus)
    }

    const [tableBody, setTableBody] = useState([])
    const [activeAuctions, setActiveAuctions] = useState([])

    let action = props.match.params.action

    const formik = useFormik({
        initialValues: {
            page: 1,
            limit: 20,
            order: '',
            orderby: '',
            filters: {
                action: {
                    value: action ? (action.toString() !== 'all' ? action.toString() : '') : null,
                    type: 'in',
                    field: 'p.market_status',
                },
                searchterm: {
                    value: '',
                    type: 'like',
                    field: 'p.title',
                },
                fromdate: {
                    value: '',
                    type: 'dategreaterequal',
                    field: 'p.date_closed',
                },
                todate: {
                    value: '',
                    type: 'datesmallerequal',
                    field: 'p.date_closed',
                },
            },
        },
        onSubmit: (values) => {
            setIsLoading(true)
            getAllProducts(values)
        },
    })

    const formikSelection = useFormik({
        initialValues: {
            action: '',
            project_id: '',
            auction_id: '',
            date_closed: dateTimeFormatFunction(new Date()),
            mainUpdate: false,
        },
        onSubmit: (values) => {
            setIsLoading(true)
            if (
                values.action === 'open' ||
                values.action === 'removed' ||
                values.action === 'unsold' ||
                values.action === 'date_closed' ||
                values.action === 'relist'
            ) {
                const value = values
                if (value.action === 'date_closed') {
                    value.date_closed = moment(value.date_closed, 'MM-DD-YYYY h:mm a').format()
                }
                changeProductStatus(value)
            } else {
                assignAuctionLot(values)
            }
        },
    })

    useEffect(() => {
        let getAllUsersPayload = {
            action: 'all',
            email: '',
            first_name: '',
            last_name: '',
            limit: '50',
            order: '',
            orderby: '',
            page: 1,
        }
        getAllUsers(getAllUsersPayload)
    }, [])
    useEffect(() => {
        return () => setIsLoading(false)
    }, [])
    useEffect(() => {
        if (all_products) {
            setTableBody(all_products.records.length ? all_products.records : [])
        }
        setIsLoading(false)
    }, [all_products])

    useEffect(() => {
        const lotsToShow = []
        if (activelots && activelots.length > 0) {
            activelots.map((lot) => {
                lotsToShow.push({ value: lot.id, show: lot.title })
            })
            setActiveAuctions(lotsToShow)
        } else {
            setActiveAuctions([])
        }
    }, [activelots])

    useEffect(() => {
        setTableBody([])
        setIsLoading(true)
        formik.values.filters.action.value = action
        formik.values.page = 1
        formik.values.filters.fromdate.value = ''
        formik.values.filters.todate.value = ''
        formik.values.filters.searchterm.value = ''
        getAllProducts(formik.values)
        return () => {
            setTableBody([])
        }
    }, [action])

    const onClickEdit = (id, secondaryID, type) => {
        if (type === 'bidcount') {
            setBidHistoryValue({
                id,
                proxy: false,
                declined: true,
                delete_bid: true,
            })
        } else {
            toggleFullScreenPopup(true, 'edit', id)
        }
    }

    const onSelectMultiProducts = (data, action) => {
        formikSelection.values.action = action
        formikSelection.values.project_id = data
        formikSelection.values.mainUpdate = false
        formikSelection.values.date_closed = dateTimeFormatFunction(new Date())
        formikSelection.values.auction_id = ''
        if (action === 'auction_assign') {
            if (activeAuctions.length) {
                formikSelection.values.auction_id = activeAuctions[0].value
            }
            setAssignAuction(true)
        } else if (
            action === 'open' ||
            action === 'removed' ||
            action === 'unsold' ||
            action === 'relist'
        ) {
            if (action === 'removed') {
                let selectedProjects = tableBody.filter(
                    (filter) => formikSelection.values.project_id.indexOf(filter.id) !== -1,
                )
                let bidsCountCheck = selectedProjects.filter((filter) => filter.bidscount === 0)
                if (bidsCountCheck.length !== formikSelection.values.project_id.length) {
                    setAlert('Listings with bids cannot be deleted', 'error')
                } else {
                    setChangeStatus(true)
                }
            } else {
                setChangeStatus(true)
            }
        } else if (action === 'date_closed') {
            formikSelection.values.mainUpdate = true
            setChangeStatus(true)
        }
    }

    const dateClosed = {
        formik: formikSelection,
        data: [
            {
                label: 'Change End Date *',
                placeholder: 'Ender End Date',
                disablePast: true,
                type: 'datetime',
                class: `col-12 ${formikSelection.values.mainUpdate ? '' : 'd-none'}`,
                name: 'date_closed',
                minDate: dateTimeFormatFunction(new Date()),
                minDateMessage: `Date should not be before minimal date ${dateTimeFormatFunction(
                    new Date(),
                )}`,
                formik: formikSelection,
            },
        ],
    }

    const assignAuctionData = [
        {
            label: 'Select existing Lots',
            type: 'select',
            placeholder: 'Select the auction lots',
            class: 'col-12',
            options: activeAuctions,
            name: 'auction_id',
            formik: formikSelection,
        },
    ]
    const tableValues = []
    if (action === 'sold') {
        tableValues.push({
            field: 'buynow_common_invoice',
            type: '',
            firstChild: false,
            sort: true,
            disablePadding: false,
            label: 'Invoice ID',
        })
    }
    tableValues.push(
        {
            field: 'id',
            type: '',
            firstChild: true,
            sort: true,
            sortField: 'p.id',
            disablePadding: false,
            label: 'ID',
        },
        {
            field: 'avatar',
            type: 'imagewithcompressedurl',
            folder: 'product',
            firstChild: false,
            sort: false,
            disablePadding: false,
            label: 'Picture',
            isCompress: true,
        },

        {
            field: 'title',
            type: '',
            firstChild: false,
            sort: true,
            sortField: 'p.title',
            disablePadding: false,
            label: 'Title',
        },
        {
            field: 'bidscount',
            click: true,
            onclick: onClickEdit,
            clickID: 'id',
            secondaryClickID: 'user_id',
            clickType: 'bidcount',
            type: '',
            sort: true,
            sortField: 'bidscount',
            firstChild: false,
            disablePadding: false,
            label: 'Bids',
        },
    )

    if (action === 'open') {
        tableValues.push(
            {
                field: 'wprice',
                type: 'price',
                firstChild: false,
                sort: true,
                sortField: 'p.wprice',
                disablePadding: false,
                label: 'Current price',
            },
            {
                field: 'rprice',
                type: 'price',
                firstChild: false,
                sort: true,
                sortField: 'p.rprice',
                disablePadding: false,
                label: 'Reserve price',
            },
            // {
            //     field: 'bprice',
            //     type: 'price',
            //     firstChild: false,
            //     sort: true,
            //     sortField: 'p.bprice',
            //     disablePadding: false,
            //     label: 'Buynow Price',
            // },
        )
    }

    if (action !== 'sold' && action !== 'relisted') {
        tableValues.push(
            {
                field: 'date_added',
                type: 'datetime',
                sort: true,
                firstChild: false,
                disablePadding: false,
                label: 'Start date',
            },
            {
                field: 'date_closed',
                type: 'datetime',
                sort: true,
                firstChild: false,
                disablePadding: false,
                label: 'End date',
            },
        )
    }

    if (action === 'sold') {
        tableValues.push(
            // {
            //     field: 'buynow_paid_date',
            //     type: 'date',
            //     sort: true,
            //     firstChild: false,
            //     disablePadding: false,
            //     label: 'Sold date',
            // },
            // {
            //     field: 'buynow_qty',
            //     type: '',
            //     firstChild: false,
            //     sort: true,
            //     disablePadding: false,
            //     label: 'Purchased Quantity',
            // },
            // {
            //     field: 'buynow_amount',
            //     type: 'price',
            //     firstChild: false,
            //     sort: true,
            //     disablePadding: false,
            //     label: 'Amount',
            // },
            {
                field: 'winner_first_name,winner_last_name',
                type: '',
                firstChild: false,
                sort: true,
                disablePadding: false,
                label: 'Winner Buyer',
            },
            {
                field: 'winner_email',
                type: '',
                firstChild: false,
                sort: true,
                disablePadding: false,
                label: 'Winner Email',
            },
        )
    }

    tableValues.push(
        {
            field: 'created_at',
            type: 'datetime',
            firstChild: false,
            sort: true,
            disablePadding: false,
            label: 'Created At',
        },
        {
            field: 'updated_at',
            type: 'datetime',
            firstChild: false,
            sort: true,
            disablePadding: false,
            label: 'Updated At',
        },
        {
            field: 'as_employees_emp_firstname,as_employees_emp_lastname',
            type: '',
            firstChild: false,
            sort: true,
            disablePadding: false,
            label: 'Posted By',
        },
    )

    if (action === 'open' || action === 'draft') {
        tableValues.push({
            field: 'action',
            type: 'button',
            clickType: 'edit',
            onclick: onClickEdit,
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Action',
            tooltipTitle: 'Edit listing',
        })
    }

    const tableActions = []

    if (action === 'draft') {
        tableActions.push(
            {
                label: 'Move to Active',
                icon: <span className="material-icons">low_priority</span>,
                onclick: onSelectMultiProducts,
                type: 'open',
            },
            {
                label: 'Assign to Auction',
                icon: <span className="material-icons">add_task</span>,
                onclick: onSelectMultiProducts,
                type: 'auction_assign',
            },
            {
                label: 'Delete',
                icon: <span className="material-icons">delete</span>,
                onclick: onSelectMultiProducts,
                type: 'removed',
            },
        )
    } else if (action === 'open') {
        tableActions.push(
            {
                label: 'Move to Unsold',
                icon: <span className="material-icons">low_priority</span>,
                onclick: onSelectMultiProducts,
                type: 'unsold',
            },
            {
                label: 'Delete',
                icon: <span className="material-icons">delete</span>,
                onclick: onSelectMultiProducts,
                type: 'removed',
            },
            {
                label: 'Change End Date',
                icon: <span className="material-icons">today</span>,
                onclick: onSelectMultiProducts,
                type: 'date_closed',
            },
        )
    } else if (action === 'sold') {
        tableActions.push({
            label: 'Move to Unsold',
            icon: <span className="material-icons">low_priority</span>,
            onclick: onSelectMultiProducts,
            type: 'unsold',
        })
    } else if (action === 'unsold') {
        tableActions.push(
            {
                label: 'Delete',
                icon: <span className="material-icons">delete</span>,
                onclick: onSelectMultiProducts,
                type: 'removed',
            },
            {
                label: 'Relist',
                icon: <span className="material-icons">restart_alt</span>,
                onclick: onSelectMultiProducts,
                type: 'relist',
            },
        )
    }

    const searchInfo = {
        formik: formik,
        data: [
            {
                label: 'Listing Title',
                placeholder: 'Enter listing title',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'text',
                name: 'searchterm',
                filter: true,
            },
            {
                label: 'Closed From Date',
                placeholder: 'Date Ending from',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'date',
                name: 'fromdate',
                filter: true,
            },
            {
                label: 'Closed To Date',
                placeholder: 'Date Ending To',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'date',
                name: 'todate',
                filter: true,
            },
        ],
    }

    useEffect(() => {
        if (responseStatusAuction) {
            if (
                responseStatusAuction.status === 'success' &&
                responseStatusAuction.from === 'auctionLot'
            ) {
                setAssignAuction(false)
                getAllProducts(formik.values)
            }
        }
    }, [responseStatusAuction])

    useEffect(() => {
        if (responseStatusProduct) {
            if (responseStatusProduct.status === 'success') {
                if (responseStatusProduct.from === 'productchange') {
                    setChangeStatus(false)
                    getAllProducts(formik.values)
                } else if (responseStatusProduct.from === 'projectAction') {
                    toggleFullScreenPopup(false)
                    getAllProducts(formik.values)
                }
            }
        }
    }, [responseStatusProduct])

    return (
        <Layout>
            <div className="dashboard listing">
                <CustomSearch searchInfo={searchInfo} formik={formik} />
                <div className="miscActions d-flex justify-content-start align-items-center flex-wrap mt-4">
                    <Button
                        variant="outlined"
                        className="btnOutlined mr-2"
                        onClick={() => toggleFullScreenPopup(true, 'new', 0)}
                    >
                        <span className="material-icons">note_add</span>Add New Single Listing
                    </Button>
                    {/* <Button
                        variant="outlined"
                        className="btnOutlined"
                        onClick={() => toggleBulkPopup(true)}
                    >
                        <span className="material-icons">note_add</span>Add New Bulk Listing
                    </Button> */}
                    {action === 'draft' || action === 'relisted' ? (
                        ''
                    ) : (
                        <Button
                            onClick={() =>
                                getAllExportDownload({
                                    action: 'export',
                                    order: 'asc',
                                    orderby: '',
                                    search: 'all',
                                    report: `${action}_product_management`,
                                })
                            }
                        >
                            <span className="material-icons">get_app</span>Export All Data
                        </Button>
                    )}
                </div>
                {isLoading ? (
                    <Loaders name="table" isLoading={isLoading} />
                ) : (
                    <CustomTable
                        formik={formik.values}
                        tableBody={tableBody}
                        tableValues={tableValues}
                        tableActions={tableActions}
                        allData={all_products}
                        changeTableFunction={getAllProducts}
                    />
                )}
            </div>
            <SingleListing data={manage} function={toggleFullScreenPopup} />
            <BulkListing data={bulkPopup} function={toggleBulkPopup} />

            <CustomDialog
                title={'Assign to lots'}
                open={assignAuction}
                function={changeAssignAuction}
            >
                <h5>Select from an existing lot to assign</h5>
                <form onSubmit={formikSelection.handleSubmit} autoComplete="nofill">
                    <div className="row lotassignCnf">
                        {Object.values(mapData(assignAuctionData))}
                    </div>
                    <div className="actionWrapper">
                        <Button onClick={() => changeAssignAuction()}>Cancel</Button>
                        <PrimaryButton type="submit" label="Assign" />
                    </div>
                </form>
            </CustomDialog>

            <CustomDialog
                title={'Change product status'}
                open={changeStatus}
                function={changeChangeStatus}
            >
                <h5>Are you sure you want to change the status</h5>
                <div className="row">{mapData(dateClosed)}</div>
                <div className="actionWrapper">
                    <Button onClick={() => changeChangeStatus()}>Cancel</Button>
                    <form onSubmit={formikSelection.handleSubmit} autoComplete="nofill">
                        <PrimaryButton type="submit" label="Confirm" />
                    </form>
                </div>
            </CustomDialog>
        </Layout>
    )
}

export default Listing
