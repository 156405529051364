import React, { useContext, useState, useEffect } from 'react'

import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../product/components/atoms/SecondaryButton'
import FullScreenPopup from '../../product/components/organisms/FullScreenPopup'
import { mapData, handleRedirectInternal } from '../../product/common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CommunicationContext from '../../product/context/communication/communicationContext'

const TemplateManage = (props) => {
    const communicationContext = useContext(CommunicationContext)
    const [isLoading, setIsLoading] = useState(true)
    const { single_static_page, getSingleStaticPage, staticPageAction } = communicationContext

    const data = props.data
    const toggleFullScreenPopup = props.function

    const validationArray = Yup.object({
        content_type: Yup.string().required('Required!'),
        content: Yup.string().when('content_type', {
            is: 'html',
            then: Yup.string().required('Required!'),
        }),
        file_path: Yup.array().when('content_type', {
            is: 'file',
            then: Yup.array().required('Required!'),
        }),
    })

    const formik = useFormik({
        initialValues: {
            id: 0,
            name: '',
            content: '',
            content_type: '',
            file_path: [],
        },
        validateOnBlur: false,
        validationSchema: validationArray,
        onSubmit: (values) => {
            if (values.content_type == 'file') {
                values.link = ''
                values.content = ''
            } else if (values.content_type == 'html') {
                values.link = ''
                values.file_path = []
            }
            setIsLoading(true)
            // values.file_path = values.file_path[0]
            staticPageAction(values)
        },
    })

    // useEffect(() => {
    //     resetFrom()
    // }, [props])

    useEffect(() => {
        if (data.status === 'edit') {
            formik.values.id = data.id
            getSingleStaticPage(formik.values)
        } else {
            resetFrom()
        }
    }, [data.id])

    useEffect(() => {
        if (single_static_page.record && data.status === 'edit') {
            formik.values.id = data.id
            const template = single_static_page.record
            template.file_path =
                template.file_path && !Array.isArray(template.file_path)
                    ? template.file_path.split(',')
                    : []
            formik.setValues(template)
            setIsLoading(false)
        } else {
            resetFrom()
            data.status !== 'edit' && setIsLoading(false)
        }
    }, [single_static_page])

    const resetFrom = () => {
        formik.setValues({
            id: 0,
            name: '',
            content: '',
            content_type: '',
            file_path: [],
        })
        formik.handleReset()
    }

    const emailTemplate = []

    if (data.status === 'new') {
        emailTemplate.push({
            label: 'Title',
            placeholder: 'Enter Title',
            class: 'col-12',
            type: 'text',
            shrink: true,
            name: 'name',
            formik: formik,
        })
    }
    emailTemplate.push(
        {
            class: 'col-12',
            name: 'content_type',
            type: 'select',
            placeholder: 'Select Content Type',
            options: [
                { show: 'HTML', value: 'html' },
                { show: 'FILE', value: 'file' },
            ],
            formik: formik,
        },
        {
            type: 'uploadDropZone',
            formik: formik,
            name: 'file_path',
            icon: 'description',
            titleText: 'Upload File',
            class: `${formik.values.content_type === 'file' ? '' : 'd-none'} col-12`,
            folder: 'video',
            multiple: false,
        },
        {
            class: `${formik.values.content_type === 'html' ? '' : 'd-none'} col-12`,
            name: 'content',
            type: 'ckeditor',
            formik: formik,
        },
    )

    return (
        <FullScreenPopup
            modaltitle={
                data.status === 'new'
                    ? 'ADD NEW STATIC PAGE'
                    : `EDIT STATIC PAGE: ${single_static_page.record.name}`
            }
            open={data.popup}
            handleClose={() => toggleFullScreenPopup(false)}
        >
            <div className="addUserModal">
                <div className="fspBody">
                    <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                        {!isLoading &&
                            ((data.status !== 'new' && formik.values.content) ||
                                data.status === 'new') && (
                                <div className="row">{Object.values(mapData(emailTemplate))}</div>
                            )}
                        {isLoading && <div>Loading....</div>}
                        <div className="actionButton d-flex justify-content-center align-items-center flex-wrap">
                            <SecondaryButton
                                label="Cancel"
                                onClick={() => toggleFullScreenPopup(false)}
                            />
                            <PrimaryButton type="submit" label="Submit" />
                        </div>
                    </form>
                </div>
            </div>
        </FullScreenPopup>
    )
}

export default TemplateManage
