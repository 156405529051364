import React, { useEffect, useContext } from 'react'
import './App.css'
import Routes from './routes'
import { BrowserRouter as Router } from 'react-router-dom'
import { setAuthToken, setIPAddress } from './product/common/api'
import { CookiesProvider } from 'react-cookie'
import AuthState from './product/context/auth/authState'
import CustomCommonState from './custom/context/common/commonState'
import VideoState from './product/context/video/videoState'
import ProductCommonState from './product/context/common/commonState'
import UserState from './product/context/user/userState'
import EmployeeState from './product/context/employee/empState'
import AlertState from './product/context/alert/alertState'
import CommunicationState from './product/context/communication/communicationState'
import BidDepositState from './product/context/bid_deposit/bidDepositState'
import TransactionState from './product/context/transaction/transactionState'
import CreditState from './product/context/credit/creditState'
import SettingState from './product/context/setting/settingState'
import ProductState from './product/context/product/productState'
import UserProductState from './product/context/userProduct/productState'
import UserBuyerState from './product/context/userBuyer/buyerState'
import UserCommonState from './product/context/userCommon/commonState'
import InvoiceState from './product/context/invoice/invoiceState'
import CartState from './product/context/cart/cartState'
import LanguageManagerState from './product/context/languageManager/languageManagerState'
import ReportState from './product/context/report/reportState'
import BidState from './product/context/bid/bidState'
import ReturnState from './product/context/return/returnState'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { SnackbarProvider } from 'notistack'
import Alerts from './product/common/alert'
import publicIp from 'public-ip'
import AuctionState from './product/context/auction/auctionState'

global.site_url = process.env.REACT_APP_DOMAIN
global.images_url = global.site_url + '/uploads/product/'
global.images_compressed_url = global.site_url + '/uploads/product/compressed'

global.ignoreStates = []
global.ignoreCountry = []
global.defaultCountry = 'AE'

if (localStorage.token) {
    setAuthToken(localStorage.token)
}

const App = () => {
    useEffect(() => {
        async function fetchData() {
            const ipaddress = await publicIp.v4()
            if (ipaddress) {
                setIPAddress(ipaddress)
            }
        }
        fetchData()
    }, [])

    return (
        <CookiesProvider>
            <CustomCommonState>
                <ProductCommonState>
                    <AuthState>
                        <AlertState>
                            <UserState>
                                <AuctionState>
                                    <ProductState>
                                        <UserProductState>
                                            <UserBuyerState>
                                                <UserCommonState>
                                                    <InvoiceState>
                                                        <CartState>
                                                            <LanguageManagerState>
                                                                <EmployeeState>
                                                                    <ReportState>
                                                                        <BidDepositState>
                                                                            <TransactionState>
                                                                                <CommunicationState>
                                                                                    <SettingState>
                                                                                        <VideoState>
                                                                                            <CreditState>
                                                                                                <ReturnState>
                                                                                                    <BidState>
                                                                                                        <MuiPickersUtilsProvider
                                                                                                            utils={
                                                                                                                MomentUtils
                                                                                                            }
                                                                                                        >
                                                                                                            <SnackbarProvider
                                                                                                                maxSnack={
                                                                                                                    3
                                                                                                                }
                                                                                                            >
                                                                                                                <div className="App">
                                                                                                                    <Alerts />
                                                                                                                    <Router>
                                                                                                                        <Routes />
                                                                                                                    </Router>
                                                                                                                </div>
                                                                                                            </SnackbarProvider>
                                                                                                        </MuiPickersUtilsProvider>
                                                                                                    </BidState>
                                                                                                </ReturnState>
                                                                                            </CreditState>
                                                                                        </VideoState>
                                                                                    </SettingState>
                                                                                </CommunicationState>
                                                                            </TransactionState>
                                                                        </BidDepositState>
                                                                    </ReportState>
                                                                </EmployeeState>
                                                            </LanguageManagerState>
                                                        </CartState>
                                                    </InvoiceState>
                                                </UserCommonState>
                                            </UserBuyerState>
                                        </UserProductState>
                                    </ProductState>
                                </AuctionState>
                            </UserState>
                        </AlertState>
                    </AuthState>
                </ProductCommonState>
            </CustomCommonState>
        </CookiesProvider>
    )
}

export default App
