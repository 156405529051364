import React, { useContext, useState, useEffect } from 'react'
import moment from 'moment'

import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../product/components/atoms/SecondaryButton'
import FullScreenPopup from '../../product/components/organisms/FullScreenPopup'
import ProductContext from '../../product/context/product/productContext'
import CustomCommonContext from '../../custom/context/common/commonContext'
import ProductCommonContext from '../../product/context/common/commonContext'
import UserContext from '../../product/context/user/userContext'

import {
    mapData,
    dateFormatFunction,
    dateTimeFormatFunction,
    handleRedirectInternal,
} from '../../product/common/components'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import { LinearProgress } from '@material-ui/core'
import Loaders from '../../product/components/molecules/Loaders'

const currentYear = new Date().getFullYear()
let yearOptions = []
for (let year = currentYear; year >= 1950; year--) {
    yearOptions.push({ show: year, value: year })
}

const SingleListing = (props) => {
    const { projectAction, single_project, getSingleProject } = useContext(ProductContext)
    const [isLoading, setIsLoading] = useState(false)
    const { resetDropZone } = useContext(ProductCommonContext)
    const [reload, setReload] = useState(false)

    const { allCategory, allLocations, allCondition, allBodyTypes } =
        useContext(CustomCommonContext)

    const { getAllUsers, all_users } = useContext(UserContext)

    const data = props.data
    const toggleFullScreenPopup = props.function

    const validationArray = Yup.object({
        title: Yup.string().required('Required!'),
        // location_id: Yup.string().required('Required!'),
        categoryTypeId: Yup.string().required('Required!'),
        subCategoryTypeId: Yup.string(),
        // conditionTypeId: Yup.string().required('Required!'),
        selltype: Yup.boolean(),
        selectedbuyer: Yup.array().when('selltype', {
            is: (avatar) => avatar,
            then: Yup.array().required('Required!'),
        }),
        date_added: Yup.string().required('Required!').nullable(),
        date_closed: Yup.string().required('Required!').nullable(),
        qty: Yup.number()
            .required('Required!')
            .positive('Should be greater than 1')
            .test('decimal', 'No decimals allowed', (number) => /^[0-9\b]+$/.test(number)),
        desc_proc: Yup.string(),
        auction: Yup.boolean().when('buynow', {
            is: false,
            then: Yup.boolean().oneOf([true], 'Enable Auction or Buy Now'),
        }),
        sprice: Yup.number().when('auction', {
            is: true,
            then: Yup.number().required('Required!').positive('Should be greater than 1'),
        }),
        rprice: Yup.number().when('auction', {
            is: true,
            then: Yup.number()
                .positive('Should be greater than 1')
                .min(Yup.ref('sprice'), 'Should be equal or greater than the starting price')
                .required('Required!'),
        }),

        est_start_price: Yup.number().required('Required!').positive('Should be greater than 1'),
        est_end_price: Yup.number().required('Required!').positive('Should be greater than 1'),
        buynow: Yup.boolean(),
        bprice: Yup.number().when('buynow', {
            is: true,
            then: Yup.number()
                .required('Required!')
                .positive('Should be greater than 1')
                .when('auction', {
                    is: true,
                    then: Yup.number().moreThan(
                        Yup.ref('rprice'),
                        'Should be greater than the reserve price',
                    ),
                }),
        }),
        // deposit: Yup.boolean(),
        featured: Yup.boolean(),
        // deposit_amount: Yup.number()
        //     .required('Required!')
        //     .positive('Should be greater than 1')
        //     .when('auction', {
        //         is: true,
        //         then: Yup.number().lessThan(
        //             Yup.ref('rprice'),
        //             'Should be lesser than the reserve price',
        //         ),
        //     }),
        uploadimages: Yup.array().required('Required!'),
        exhaustAudio: Yup.array(),
        model: Yup.string(),
        vehicle_type: Yup.string(),
        engine: Yup.string(),
        manufacturing_year: Yup.number(),
        registration_year: Yup.number(),
        drive: Yup.string(),
        fuel: Yup.string(),
        doors: Yup.number(),
        seating_capacity: Yup.number(),
        transmission: Yup.string(),
        ownership: Yup.string(),
        open_notification: Yup.boolean(),
        // peak_torque: Yup.string(),
        // peak_power: Yup.string(),
        // kms_done: Yup.number().positive('Should be greater than 1'),
        exterior_color: Yup.string(),
        cylinders: Yup.number().positive('Should be greater than 1'),
    })

    const formik = useFormik({
        initialValues: {
            id: 0,
            title: '',
            location_id: '',
            categoryTypeId: '',
            subCategoryTypeId: '',
            business_categoryid: [],
            conditionTypeId: '',
            selltype: false,
            selectedbuyer: [],
            date_added: null,
            date_closed: null,
            qty: 0,
            desc_proc: '',
            auction: false,
            sprice: 0,
            rprice: 0,
            est_start_price: 0,
            est_end_price: 0,
            buynow: false,
            bprice: 0,
            // deposit: false,
            featured: false,
            // deposit_amount: 0,
            uploadimages: [],
            exhaustAudio: [],
            market_status: 'open',
            auctiontype: 'live',
            group_carts: 1,
            local_pickup_check: 1,
            shipping_check: 0,
            company: '',
            model: '',
            vehicle_type: '',
            engine: '',
            manufacturing_year: '',
            registration_year: '',
            drive: '',
            fuel: '',
            doors: '',
            seating_capacity: '',
            transmission: '',
            ownership: '',
            open_notification: false,
            // peak_torque: '',
            // peak_power: '',
            // kms_done: '',
            exterior_color: '',
            cylinders: '',
        },
        validateOnBlur: false,
        validationSchema: validationArray,
        onSubmit: (values) => {
            setIsLoading(true)
            const value = values
            value.date_added = moment(value.date_added, 'MM-DD-YYYY h:mm a').format()
            value.date_closed = moment(value.date_closed, 'MM-DD-YYYY h:mm a').format()

            if (single_project && single_project.record && single_project.record.wprice) {
                value.wprice = single_project.record.wprice
            }

            projectAction(value)
        },
    })

    const formSubmit = (data) => {
        formik.values.market_status = data
        formik.submitForm().then(setIsLoading(false)).catch(setIsLoading(false))
    }

    useEffect(() => {
        if (data.status === 'edit') {
            setIsLoading(true)
            formik.values.id = data.id
            getSingleProject(formik.values)
        } else {
            setIsLoading(false)
            formik.values.id = 0
            resetFrom()
        }
    }, [data])
    useEffect(() => {
        if (data.status == 'new') {
            console.log(data.status, 'data.status')
            setIsLoading(false)
        }
    }, [data.status])
    useEffect(() => {
        setIsLoading(false)
        return () => {
            setIsLoading(false)
        }
    }, [])
    useEffect(() => {
        if (formik.values.business_categoryid.length) {
            let allBuyers = []
            all_users.records.map((user) => {
                allBuyers.push(parseInt(user.id, 10))
                return true
            })
            if (data.status !== 'edit') {
                formik.values.selectedbuyer = allBuyers
            }
            setReload(!reload)
        }
    }, [all_users])

    useEffect(() => {
        if (formik.values.business_categoryid) {
            console.log('formik.values.business_categoryid', formik.values.business_categoryid)
            getAllUsers({
                email: '',
                first_name: '',
                last_name: '',
                page: 1,
                limit: 1000,
                order: '',
                orderby: '',
                filters: {
                    action: {
                        value: 'active',
                        type: 'in',
                        field: 'u.status',
                    },
                    business_category: {
                        value: formik.values.business_categoryid
                            ? formik.values.business_categoryid
                            : [],
                        type: 'findinarray',
                        field: 'cu.business_category',
                    },
                },

                business_categoryid: formik.values.business_categoryid,
            })
        }
    }, [formik.values.business_categoryid])

    useEffect(() => {
        if (single_project.record && data.status === 'edit') {
            const project = single_project.record
            formik.values.title = project.title
            // formik.values.location_id = project.location_id
            formik.values.categoryTypeId = project.categoryTypeId
            formik.values.subCategoryTypeId = project.subCategoryTypeId
                ? project.subCategoryTypeId
                : ''
            formik.values.business_categoryid = project.business_categoryid
                ? project.business_categoryid.toString().split(',').map(Number)
                : []
            // formik.values.conditionTypeId = project.conditionTypeId
            formik.values.bodyTypeId = project.bodyTypeId
            formik.values.selltype = project.selltype === '1' ? true : false
            formik.values.selectedbuyer = project.selectedbuyer
                ? project.selectedbuyer.split(',').map(Number)
                : []
            formik.values.date_added = dateTimeFormatFunction(project.date_added)
            formik.values.date_closed = dateTimeFormatFunction(project.date_closed)
            formik.values.qty = project.qty
            formik.values.desc_proc = project.desc_proc
            formik.values.auction = project.auction
            formik.values.sprice = project.sprice
            formik.values.rprice = project.rprice
            formik.values.est_start_price = project.est_start_price
            formik.values.est_end_price = project.est_end_price
            formik.values.buynow = project.buynow
            formik.values.bprice = project.bprice
            //formik.values.deposit = project.deposit
            //formik.values.deposit_amount = project.deposit_amount
            formik.values.model = project.model
            formik.values.vehicle_type = project.vehicle_type
            formik.values.manufacturing_year = project.manufacturing_year
            formik.values.engine = project.engine
            formik.values.transmission = project.transmission
            formik.values.ownership = project.ownership
            formik.values.open_notification = project.open_notification || false
            // formik.values.peak_torque = project.peak_torque
            // formik.values.peak_power = project.peak_power
            // formik.values.kms_done = project.kms_done
            formik.values.doors = project.doors
            formik.values.drive = project.drive
            formik.values.seating_capacity = project.seating_capacity
            formik.values.registration_year = project.registration_year
            formik.values.fuel = project.fuel
            formik.values.exterior_color = project.exterior_color
            formik.values.cylinders = project.cylinders
            formik.values.featured = project.featured
            formik.values.uploadimages = project.uploadimages.split(',').length
                ? project.uploadimages.split(',')
                : []
            formik.values.exhaustAudio = project?.exhaustAudio?.split(',').length
                ? project.exhaustAudio.split(',')
                : []
            formik.values.market_status = project.market_status
            setReload(!reload)
            setIsLoading(false)
        }
    }, [single_project])

    const resetFrom = () => {
        console.log('reset form')
        formik.values.id = 0
        formik.values.title = ''
        // formik.values.location_id = ''
        formik.values.categoryTypeId = ''
        formik.values.subCategoryTypeId = ''
        formik.values.business_categoryid = []
        // formik.values.conditionTypeId = ''
        formik.values.selltype = false
        formik.values.selectedbuyer = []
        formik.values.date_added = null
        formik.values.date_closed = null
        formik.values.qty = 0
        formik.values.desc_proc = ''
        formik.values.auction = false
        formik.values.sprice = 0
        formik.values.rprice = 0
        formik.values.est_start_price = 0
        formik.values.est_end_price = 0
        formik.values.buynow = false
        formik.values.bprice = 0
        // formik.values.deposit = false
        // formik.values.deposit_amount = 0
        formik.values.company = ''
        formik.values.uploadimages = []
        formik.values.exhaustAudio = []
        formik.values.market_status = 'open'
        formik.values.model = ''
        formik.values.vehicle_type = ''
        formik.values.engine = ''
        formik.values.manufacturing_year = ''
        formik.values.registration_year = ''
        formik.values.drive = ''
        formik.values.fuel = ''
        formik.values.doors = ''
        formik.values.seating_capacity = ''
        formik.values.transmission = ''
        formik.values.ownership = ''
        formik.values.open_notification = false
        // formik.values.peak_torque = ''
        // formik.values.peak_power = ''
        // formik.values.kms_done = ''
        formik.values.exterior_color = ''
        formik.values.cylinders = ''
        formik.handleReset()
        resetDropZone('uploadimages')
        resetDropZone('exhaustAudio')
        setReload(!reload)
    }

    const productListing = [
        {
            label: 'Lisiting Title *',
            type: 'text',
            placeholder: 'Enter your Listing Title',
            class: 'col-sm-6 col-12',
            name: 'title',
            formik: formik,
        },
        // {
        //     label: 'Location *',
        //     placeholder: 'Select location',
        //     type: 'select',
        //     class: 'col-sm-6 col-12',
        //     options: allLocations
        //         .filter((value) => value.active === 1 || formik.values.location_id === value.id)
        //         .map((location) => {
        //             let locationChanged = {}
        //             locationChanged.show = location.name
        //             locationChanged.value = location.id
        //             return locationChanged
        //         }),
        //     defaultOptionValue: 'Select a Location',
        //     name: 'location_id',
        //     formik: formik,
        // },
        {
            label: 'Brand *',
            placeholder: 'Select brand',
            type: 'select',
            class: 'col-sm-6 col-12',
            options: allCategory
                .filter(
                    (maincat) =>
                        (maincat.active === 1 ||
                            (formik.values.categoryTypeId &&
                                formik.values.categoryTypeId === maincat.id)) &&
                        maincat.level === 0,
                )
                .map((maincat) => {
                    let categoryChanged = {}
                    categoryChanged.show = maincat.description
                    categoryChanged.value = maincat.id
                    return categoryChanged
                }),
            defaultOptionValue: 'Select a Category',
            name: 'categoryTypeId',
            formik: formik,
        },
        {
            label: 'Model',
            placeholder: 'Select model',
            type: 'select',
            class: 'col-sm-6 col-12',
            options: allCategory
                .filter((subcat) => {
                    if (formik.values.categoryTypeId && parseInt(subcat.level, 10) === 1) {
                        if (
                            parseInt(subcat.active, 10) === 1 ||
                            (formik.values.subCategoryTypeId &&
                                formik.values.subCategoryTypeId === subcat.id)
                        ) {
                            return (
                                parseInt(formik.values.categoryTypeId, 10) ===
                                parseInt(subcat.level_id, 10)
                            )
                        } else {
                            return false
                        }
                    } else {
                        return false
                    }
                })
                .map((subcat) => {
                    let subCategoryChanged = {}
                    subCategoryChanged.show = subcat.description
                    subCategoryChanged.value = subcat.id
                    return subCategoryChanged
                }),
            defaultOptionValue: 'Select a Sub Category',
            name: 'subCategoryTypeId',
            formik: formik,
        },
        {
            label: 'Body Type',
            placeholder: 'Select Body Type',
            type: 'select',
            class: 'col-sm-6 col-12',
            options: allBodyTypes.map((type) => {
                let obj = {}
                obj.show = type.description
                obj.value = type.id
                return obj
            }),
            defaultOptionValue: 'Select Body Type',
            name: 'bodyTypeId',
            formik: formik,
        },
        // {
        //     label: 'Condition *',
        //     placeholder: 'Select condition',
        //     type: 'select',
        //     class: 'col-sm-6 col-12',
        //     options: allCondition
        //         .filter(
        //             (maincat) =>
        //                 maincat.active === 1 ||
        //                 (formik.values.conditionTypeId &&
        //                     formik.values.conditionTypeId === maincat.id),
        //         )
        //         .map((location) => {
        //             let conditionChanged = {}
        //             conditionChanged.show = location.description
        //             conditionChanged.value = location.id
        //             return conditionChanged
        //         }),
        //     defaultOptionValue: 'Select a Condition',
        //     name: 'conditionTypeId',
        //     formik: formik,
        // },
        // {
        //     label: 'Company *',
        //     type: 'text',
        //     placeholder: 'Enter company name',
        //     class: 'col-sm-6 col-12',
        //     name: 'company',
        //     formik: formik,
        // },
        // {
        //     label: 'Only Invited',
        //     type: 'checkbox',
        //     class: 'col-12',
        //     name: 'selltype',
        //     formik: formik,
        // },
        // {
        //     label: 'Business Category',
        //     placeholder: 'Select condition',
        //     type: 'multiselect',
        //     class: 'col-sm-6 col-12',
        //     options: allBusinessCategory
        //         .filter(
        //             (value) =>
        //                 value.active === 1 || formik.values.business_categoryid.includes(value.id),
        //         )
        //         .map((user) => {
        //             let userChanged = {}
        //             userChanged.show = user.description
        //             userChanged.value = user.id
        //             return userChanged
        //         }),
        //     disabled: formik.values.selltype ? false : true,
        //     defaultOptionValue: 'Select a Business Category',
        //     name: 'business_categoryid',
        //     formik: formik,
        // },
        // {
        //     label: 'Invited Company',
        //     placeholder: 'Select condition',
        //     type: 'multiselect',
        //     class: 'col-sm-6 col-12',
        //     options: formik.values.business_categoryid.length
        //         ? all_users.records.map((user) => {
        //               let userChanged = {}
        //               userChanged.show = user.companyname + ' (' + user.email + ')'
        //               userChanged.value = user.id
        //               return userChanged
        //           })
        //         : [],
        //     disabled: formik.values.selltype ? false : true,
        //     defaultOptionValue: 'Select a Condition',
        //     name: 'selectedbuyer',
        //     formik: formik,
        // },

        {
            label: 'Start date *',
            placeholder: 'Enter Start date',
            type: 'datetime',
            disablePast: true,
            class: 'col-md-3 col-sm-6 col-12',
            name: 'date_added',
            formik: formik,
        },
        {
            label: 'End Date *',
            placeholder: 'Enter End Date',
            disablePast: true,
            type: 'datetime',
            class: 'col-md-3 col-sm-6 col-12',
            name: 'date_closed',
            minDate: dateTimeFormatFunction(formik.values.date_added),
            minDateMessage: `Date should not be before minimal date ${dateTimeFormatFunction(
                formik.values.date_added,
            )}`,

            formik: formik,
        },
        {
            label: 'Quantity *',
            type: 'number',
            placeholder: 'Enter Quantity',
            class: 'col-md-3 col-sm-6 col-12',
            name: 'qty',
            formik: formik,
        },
        {
            label: 'Description *',
            placeholder: 'Enter Description',
            type: 'ckeditor',
            class: 'col-12',
            name: 'desc_proc',
            formik: formik,
        },
        {
            label: 'Enable Auction',
            type: 'checkbox',
            class: 'col-12',
            name: 'auction',
            formik: formik,
        },
        {
            label: 'Auction start price',
            placeholder: 'Enter starting price',
            type: 'number',
            class: 'col-md-3 col-sm-6 col-12',
            name: 'sprice',
            disabled: formik.values.auction ? false : true,
            formik: formik,
        },
        {
            label: 'Auction reserve price',
            placeholder: 'Enter reserve price',
            type: 'number',
            class: 'col-md-3 col-sm-6 col-12',
            name: 'rprice',
            disabled: formik.values.auction ? false : true,
            formik: formik,
        },
        {
            label: 'Estimated start price',
            placeholder: 'Enter est start price',
            type: 'number',
            class: 'col-md-3 col-sm-6 col-12',
            name: 'est_start_price',
            formik: formik,
        },
        {
            label: 'Estimated end price',
            placeholder: 'Enter est end price',
            type: 'number',
            class: 'col-md-3 col-sm-6 col-12',
            name: 'est_end_price',
            formik: formik,
        },
        // {
        //     label: 'Enable Buy Now',
        //     type: 'checkbox',
        //     class: 'col-6',
        //     name: 'buynow',
        //     formik: formik,
        // },
        // {
        //     label: 'Buy Now price',
        //     placeholder: 'Enter buy now price',
        //     type: 'number',
        //     class: 'col-md-3 col-sm-6 col-12',
        //     name: 'bprice',
        //     disabled: formik.values.buynow ? false : true,
        //     formik: formik,
        // },
        // {
        //     content: '(Per Quantity)',
        //     type: 'misc',
        // },
        // {
        //     label: 'Enable Deposit',
        //     type: 'checkbox',
        //     class: 'col-6',
        //     name: 'deposit',
        //     formik: formik,
        // },
        {
            label: 'Make this auction featured',
            placeholder: '',
            type: 'checkbox',
            class: 'col-12',
            name: 'featured',
            formik: formik,
        },
        // {
        //     label: 'Deposit price',
        //     placeholder: 'Enter Deposit price',
        //     type: 'number',
        //     class: 'col-md-3 col-sm-6 col-12',
        //     name: 'deposit_amount',
        //     disabled: formik.values.deposit ? false : true,
        //     formik: formik,
        // },
        {
            label: 'Vehicle Type',
            placeholder: 'Enter vehilce type',
            type: 'text',
            class: 'col-4',
            name: 'vehicle_type',
            formik: formik,
        },
        {
            label: 'Model',
            placeholder: 'Enter model',
            type: 'text',
            class: 'col-2',
            name: 'model',
            formik: formik,
        },
        {
            label: 'Engine',
            placeholder: 'Enter engine',
            type: 'text',
            class: 'col-2',
            name: 'engine',
            formik: formik,
        },
        {
            label: 'Manufacuring Year',
            placeholder: 'Enter manufacturing year',
            type: 'number',
            class: 'col-2',
            name: 'manufacturing_year',
            formik: formik,
        },
        {
            label: 'Registration Year',
            placeholder: 'Enter registration year',
            type: 'number',
            class: 'col-2',
            name: 'registration_year',
            formik: formik,
        },
        {
            label: 'Drive',
            placeholder: 'Enter drive',
            type: 'text',
            class: 'col-4',
            name: 'drive',
            formik: formik,
        },
        {
            label: 'Fuel',
            placeholder: 'Enter fuel',
            type: 'text',
            class: 'col-4',
            name: 'fuel',
            formik: formik,
        },
        {
            label: 'Doors',
            placeholder: 'Select # of doors',
            type: 'select',
            options: [
                { value: 1, show: 1 },
                { value: 2, show: 2 },
                { value: 3, show: 3 },
                { value: 4, show: 4 },
                { value: 5, show: 5 },
                { value: 6, show: 6 },
            ],
            class: 'col-2',
            name: 'doors',
            formik: formik,
        },
        {
            label: 'Seating Capacity',
            placeholder: 'Select seating capacity',
            type: 'select',
            options: [
                { value: 1, show: 1 },
                { value: 2, show: 2 },
                { value: 3, show: 3 },
                { value: 4, show: 4 },
                { value: 5, show: 5 },
                { value: 6, show: 6 },
            ],
            class: 'col-2',
            name: 'seating_capacity',
            formik: formik,
        },
        {
            label: 'Transmission',
            placeholder: 'Enter transmission',
            type: 'text',
            class: 'col-sm-3 col-12',
            name: 'transmission',
            formik: formik,
        },
        {
            label: 'Ownership',
            placeholder: 'Select ownership',
            type: 'select',
            options: [
                { show: '1st', value: '1st' },
                { show: '2nd', value: '2nd' },
                { show: '3rd', value: '3rd' },
                { show: '4th', value: '4th' },
                { show: '5th', value: '5th' },
                { show: '6th', value: '6th' },
            ],
            class: 'col-sm-3 col-12',
            name: 'ownership',
            formik: formik,
        },

        // {
        //     label: 'Peak Torque',
        //     placeholder: 'Enter peak torque',
        //     type: 'text',
        //     class: 'col-3',
        //     name: 'peak_torque',
        //     formik: formik,
        // },
        // {
        //     label: 'Peak Tower',
        //     placeholder: 'Enter peak tower',
        //     type: 'text',
        //     class: 'col-3',
        //     name: 'peak_power',
        //     formik: formik,
        // },

        // {
        //     label: 'Kms done',
        //     placeholder: 'Enter kms done',
        //     type: 'number',
        //     class: 'col-3',
        //     name: 'kms_done',
        //     formik: formik,
        //     endAdornment: 'kms',
        // },
        {
            label: 'Exterior Color',
            placeholder: 'Enter exterior color',
            type: 'text',
            class: 'col-sm-3 col-12',
            name: 'exterior_color',
            formik: formik,
        },
        {
            label: 'Cylinders',
            placeholder: 'Enter cylinders',
            type: 'number',
            class: 'col-sm-3 col-12',
            name: 'cylinders',
            formik: formik,
        },
        {
            type: 'uploadDropZone',
            formik: formik,
            name: 'uploadimages',
            titleText: 'Upload product images *',
            innerText: 'Recommended dimensions: 1040px * 670px',
            class: 'col-12',
            isCompress: true,
            folder: 'product',
            multiple: true,
            reorder: true,
            quality: 80,
            resultWidth: 1040,
            resultHeight: 670,
            accept: 'image/*',
        },
        {
            type: 'uploadDropZone',
            formik: formik,
            name: 'exhaustAudio',
            titleText: 'Upload exhaust note sound',
            innerText: 'Only MP3 file accepted',
            class: 'col-12',
            folder: 'product',
            isCompress: false,
            multiple: false,
            accept: 'audio/*',
        },
        {
            label: 'Send Open Notification',
            type: 'checkbox',
            class: 'col-12',
            name: 'open_notification',
            formik: formik,
        },
    ]

    useEffect(() => {
        if (formik.values.date_closed && formik.values.date_added) {
            let dateClosed = moment(formik.values.date_closed, 'MM-DD-YYYY h:mm a').format()
            let dateAdded = moment(formik.values.date_added, 'MM-DD-YYYY h:mm a').format()
            if (dateClosed <= dateAdded) {
                formik.values.date_closed = null
                setReload(!reload)
            }
        }
    }, [formik.values.date_added, formik.values.date_closed])

    useEffect(() => {
        if (!formik.values.selltype) {
            formik.values.business_categoryid = []
            formik.values.selectedbuyer = []
            setReload(!reload)
        }
    }, [formik.values.selltype])

    return (
        <FullScreenPopup
            modaltitle={
                data.status === 'new'
                    ? 'ADD NEW LISTING'
                    : `EDIT LISTING: ${single_project.record.title}`
            }
            open={data.popup}
            handleClose={() => {
                setIsLoading(false)
                toggleFullScreenPopup(false)
            }}
        >
            <div className="addUserModal">
                <div className="fspBody">
                    {formik.isSubmitting ? <LinearProgress /> : null}
                    {isLoading ? (
                        <div className="fspLoader">
                            <Loaders isLoading={isLoading} />
                        </div>
                    ) : (
                        <>
                            {data.status === 'edit' && (
                                <div className="listingInfo">
                                    <span className="material-icons">info</span>
                                    <h6>
                                        Created at:
                                        <span>
                                            {dateTimeFormatFunction(
                                                single_project.record.created_at,
                                            )}
                                        </span>
                                    </h6>
                                    <span className="liDivider">|</span>
                                    <h6>
                                        Last update at:
                                        <span>
                                            {dateTimeFormatFunction(
                                                single_project.record.updated_at,
                                            )}
                                        </span>
                                    </h6>
                                    <span className="liDivider">|</span>

                                    <h6>
                                        Posted by:
                                        <span>
                                            {single_project.record.as_employees_emp_firstname}{' '}
                                            {single_project.record.as_employees_emp_lastname}{' '}
                                        </span>
                                    </h6>
                                    <span className="liDivider">|</span>
                                    <h6>
                                        Bid Count:
                                        <span>{single_project.record.bidscount}</span>
                                    </h6>
                                </div>
                            )}
                            {/* <form onSubmit={formik.handleSubmit} autoComplete="nofill"> */}
                            <div className="row">{Object.values(mapData(productListing))}</div>
                            <div className="actionButton d-flex justify-content-center align-items-center flex-wrap">
                                <SecondaryButton
                                    label="Cancel"
                                    onClick={() => {
                                        setIsLoading(false), toggleFullScreenPopup(false)
                                    }}
                                />
                                {formik.values.id ? (
                                    <>
                                        {formik.values.market_status === 'draft' && (
                                            <>
                                                <SecondaryButton
                                                    onClick={() => {
                                                        formSubmit('draft')
                                                    }}
                                                    type="button"
                                                    label="Save as Draft"
                                                />
                                                <PrimaryButton
                                                    onClick={() => {
                                                        formSubmit('open')
                                                    }}
                                                    type="button"
                                                    label="Submit"
                                                />
                                            </>
                                        )}
                                        {formik.values.market_status === 'open' && (
                                            <>
                                                <PrimaryButton
                                                    onClick={() => {
                                                        formSubmit('open')
                                                    }}
                                                    type="button"
                                                    label="Update"
                                                />
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <SecondaryButton
                                            onClick={() => {
                                                formSubmit('draft')
                                            }}
                                            type="button"
                                            label="Save as Draft"
                                        />
                                        <PrimaryButton
                                            onClick={() => {
                                                formSubmit('open')
                                            }}
                                            type="button"
                                            label="Submit"
                                        />
                                    </>
                                )}
                            </div>
                            {/* </form> */}
                        </>
                    )}
                </div>
            </div>
        </FullScreenPopup>
    )
}

export default SingleListing
