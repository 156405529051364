import React, { useReducer } from 'react'
import CommonContext from './commonContext'
import CommonReducer from './commonReducer'
import { apiCall } from '../../../product/common/api'
import { response } from '../../../product/context/common'

import { COMMON_VALUES, RESPONSE_STATUS, CLEAR_RESPONSE } from './commonTypes'

const CommonState = (props) => {
    const initialState = {
        allCategory: [],
        allBusinessCategory: [],
        allLocations: [],
        allCondition: [],
        allBodyTypes: [],
        allNotifications: [],
        allVatCategory: [],
        allConfigurationVariables: {},
        allFeatures: {},
        allBidIncrements: [],
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(CommonReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getGlobalVariable = async (type) => {
        const [res] = await Promise.all([apiCall('post', 'getDefault', {}, '', '', true)])
        if (res.data.status === 'success') {
            dispatch({
                type: COMMON_VALUES,
                payload: {
                    allCategory: res.data.data.responseData.tableValues.allcategoryTypes.filter(
                        (value) => value.active != 2,
                    ),
                    // allBusinessCategory: res.data.data.responseData.tableValues.allbusiness_category.filter(
                    //     (value) => value.active != 2,
                    // ),
                    allVatCategory:
                        res.data.data.responseData.tableValues.allvat_registration.filter(
                            (value) => value.active != 2,
                        ),
                    allNotifications: res.data.data.responseData.tableValues.allall_notifications,
                    allConfigurationVariables: res.data.data.responseData.variables,
                    allLocations: res.data.data.responseData.tableValues.alllocations.filter(
                        (value) => value.active != 2,
                    ),
                    allCondition: res.data.data.responseData.tableValues.allconditionTypes.filter(
                        (value) => value.active != 2,
                    ),
                    allFeatures: res.data.data.responseData.features,
                    allBidIncrements: res.data.data.responseData.allBidIncrements,
                    bidIncrementDefault: res.data.data.responseData.bidIncrementDefault,
                    allBodyTypes: res.data.data.responseData.tableValues.allbodyTypes,
                },
            })
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })
    return (
        <CommonContext.Provider
            value={{
                allCategory: state.allCategory,
                allBusinessCategory: state.allBusinessCategory,
                allVatCategory: state.allVatCategory,
                allNotifications: state.allNotifications,
                allLocations: state.allLocations,
                allCondition: state.allCondition,
                allBidIncrements: state.allBidIncrements,
                allFeatures: state.allFeatures,
                allConfigurationVariables: state.allConfigurationVariables,
                allBodyTypes: state.allBodyTypes,
                responseStatus: state.responseStatus,
                getGlobalVariable,
                clearResponse,
            }}
        >
            {props.children}
        </CommonContext.Provider>
    )
}

export default CommonState
