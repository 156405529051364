import React, { useContext, useEffect } from 'react'
import { LOGO, SITE_NAME } from '../../../../utils/UI'
import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import './Header.css'
import { Link } from 'react-router-dom'
import AuthContext from '../../../../product/context/auth/authContext'
import AlertContext from '../../../../product/context/alert/alertContext'
import SettingContext from '../../../../product/context/setting/settingContext'
import CommunicationContext from '../../../../product/context/communication/communicationContext'
import CartContext from '../../../../product/context/cart/cartContext'
import UserContext from '../../../../product/context/user/userContext'
import AuctionContext from '../../../../product/context/auction/auctionContext'
import ProductContext from '../../../../product/context/product/productContext'
import BidContext from '../../../../product/context/bid/bidContext'
import ReturnContext from '../../../../product/context/return/returnContext'

import { handleRedirectInternal } from '../../../../product/common/components'
import EmployeeContext from '../../../../product/context/employee/empContext'
import LanguageManagerContext from '../../../../product/context/languageManager/languageManagerContext'
import BidHistory from '../../../components/organisms/BidHistory'
import TTWBidHistory from '../../../components/organisms/TTWBidHistory'
{/* prettier-ignore */}

const Header = () => {
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const communicationContext = useContext(CommunicationContext)
    const settingContext = useContext(SettingContext)
    const cartContext = useContext(CartContext)
    const returnContext = useContext(ReturnContext)

    const userContext = useContext(UserContext)
    const employeeContext = useContext(EmployeeContext)
    const auctionContext = useContext(AuctionContext)
    const productContext = useContext(ProductContext)
    const bidContext = useContext(BidContext)

    const languageManagerContext = useContext(LanguageManagerContext)

    const {
        responseStatus: responseStatusAuth,
        clearResponse: clearResponseAuth,
        logout,
    } = authContext
    const { setAlert } = alertContext

    const { responseStatus: responseStatusCommuncation, clearResponse: clearResponseCommuncation } =
        communicationContext

    const { responseStatus: responseStatusReturn, clearResponse: clearResponseReturn } =
        returnContext

    // const responseStatusReturn = null
    // const clearResponseReturn = () => {}

    const {
        responseStatus: responseStatusLanguageManager,
        clearResponse: clearResponseLanguageManager,
    } = languageManagerContext

    const { responseStatus: responseStatusSetting, clearResponse: clearResponseSetting } =
        settingContext

    const { responseStatus: responseStatusUser, clearResponse: clearResponseUser } = userContext

    const { responseStatus: responseStatusEmployee, clearResponse: clearResponseEmployee } =
        employeeContext

    const { responseStatus: responseStatusAuction, clearResponse: clearResponseAuction } =
        auctionContext

    const { responseStatus: responseStatusProduct, clearResponse: clearResponseProduct } =
        productContext

    const { responseStatus: responseStatusBid, clearResponse: clearResponseBid } = bidContext

    const { responseStatus: responseStatusCart, clearResponse: clearResponseCart } = cartContext

    useEffect(() => {
        if (responseStatusAuth) {
            setAlert(responseStatusAuth.message, responseStatusAuth.status)
            clearResponseAuth()
        }

        if (responseStatusCommuncation) {
            setAlert(responseStatusCommuncation.message, responseStatusCommuncation.status)
            clearResponseCommuncation()
        }
        if (responseStatusUser) {
            setAlert(responseStatusUser.message, responseStatusUser.status)
            clearResponseUser()
        }
        if (responseStatusEmployee) {
            setAlert(responseStatusEmployee.message, responseStatusEmployee.status)
            clearResponseEmployee()
        }
        if (responseStatusAuction) {
            setAlert(responseStatusAuction.message, responseStatusAuction.status)
            clearResponseAuction()
        }
        if (responseStatusProduct) {
            setAlert(responseStatusProduct.message, responseStatusProduct.status)
            clearResponseProduct()
        }
        if (responseStatusSetting) {
            setAlert(responseStatusSetting.message, responseStatusSetting.status)
            clearResponseSetting()
        }
        if (responseStatusCart) {
            setAlert(responseStatusCart.message, responseStatusCart.status)
            clearResponseCart()
        }
        if (responseStatusLanguageManager) {
            setAlert(responseStatusLanguageManager.message, responseStatusLanguageManager.status)
            clearResponseLanguageManager()
        }
        if (responseStatusBid) {
            setAlert(responseStatusBid.message, responseStatusBid.status)
            clearResponseBid()
        }
        if (responseStatusReturn) {
            setAlert(responseStatusReturn.message, responseStatusReturn.status)
            clearResponseReturn()
        }
    }, [
        responseStatusAuth,
        responseStatusCommuncation,
        responseStatusUser,
        responseStatusEmployee,
        responseStatusAuction,
        responseStatusProduct,
        responseStatusBid,
        responseStatusSetting,
        responseStatusCart,
        responseStatusLanguageManager,
        responseStatusReturn,
    ])

    const history = useHistory()

    const onLogOut = () => {
        logout()
        setAlert('Logged out successfully', 'success')
        handleRedirectInternal(history, '')
    }

    return (
        <div className="header">
            <div className="customContainer d-flex justify-content-between align-items-center smpCls">
                <div className="headLogo">
                    <Link to="/dashboard">
                        <img src={LOGO} alt={`${SITE_NAME} Logo`} />
                    </Link>
                </div>
                <div>
                    <Button
                        onClick={() => onLogOut()}
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                    >
                        Logout
                        <span className="material-icons">power_settings_new</span>
                    </Button>
                </div>
            </div>
            <BidHistory />
            <TTWBidHistory />
        </div>
    )
}

export default Header
