import React, { useContext, useEffect, useState } from 'react'
import { Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import Layout from '../../product/components/templates/Layout'
import commonContext from '../../product/context/common/commonContext'
import './Dashboard.css'
import Loaders from '../../product/components/molecules/Loaders'
import { SIDE_PANEL } from '../../utils/UI'
import AlertContext from '../../product/context/alert/alertContext'

const Dashboard = () => {
    const hue = 0
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(true)
    const { setAlert } = useContext(AlertContext)
    const handleRedirect = (path) => {
        if (SIDE_PANEL.length) {
            let isListingPresent =
                Object.keys(SIDE_PANEL.filter((menu) => menu.label === 'Listing')).length > 0
            if (isListingPresent) {
                history.push(`/${path}`)
                window.scrollTo(0, 0)
            } else {
                setAlert('Unauthorized access', 'error')
            }
        }
    }
    const dashboardContext = useContext(commonContext)
    const { getMainDashboardData, mainDashboardData } = dashboardContext
    const [getdashboard, setDashboard] = useState({
        activeCount: 0,
        draftCount: 0,
        soldbuynowCount: 0,
        unsoldCount: 0,
    })
    const cardList = [
        {
            label: 'Active',
            count: getdashboard.activeCount,
            path: 'listing/open',
        },
        {
            label: 'Draft',
            count: getdashboard.draftCount,
            path: 'listing/draft',
        },
        {
            label: 'Sold',
            count: getdashboard.soldCount,
            path: 'listing/sold',
        },
        {
            label: 'Unsold',
            count: getdashboard.unsoldCount,
            path: 'listing/unsold',
        },
    ]
    useEffect(() => {
        setIsLoading(true)
        getMainDashboardData()
    }, [])

    useEffect(() => {
        setIsLoading(false)
        if (Object.keys(mainDashboardData).length > 0) {
            console.log('sathis', mainDashboardData)
            setDashboard(mainDashboardData)
        }
    }, [mainDashboardData])

    return (
        <Layout>
            <div className="dashboard">
                <div className="dashboardCard">
                    {cardList.map((data, index) => (
                        <div
                            key={index}
                            className="infoCard"
                            style={{ filter: `hue-rotate(${hue + index * 40}deg)` }}
                        >
                            <Button onClick={() => handleRedirect(data.path)}>
                                <h3>{data.count}</h3>
                                <h6>{data.label}</h6>
                            </Button>
                        </div>
                    ))}
                </div>
            </div>
        </Layout>
    )
}

export default Dashboard
