//LOGO
export const LOGO = '/assets/images/Logo.png'
export const LOGO_BLACK = '/assets/images/Logo.png'

export const BRAND_LOGO = '/assets/images/Logo.png'

//SITE NAME
export const SITE_NAME = 'Epic Road Auctions'

// SIDE PANEL LIST
export const SIDE_PANEL = [
    {
        label: 'Dashboard',
        path: '/dashboard',
        type: 'single',
    },
    // {
    //     label: 'Auction Lots',
    //     path: '/auction/all',
    //     type: 'single',
    // },
    {
        label: 'Listing',
        type: 'multiple',
        path: 'listing',
        subLinks: [
            {
                label: 'Active',
                path: '/listing/open',
            },
            {
                label: 'Sold',
                path: '/listing/sold',
            },
            {
                label: 'Unsold',
                path: '/listing/unsold',
            },
            {
                label: 'Draft',
                path: '/listing/draft',
            },
            // {
            //     label: 'Relisted',
            //     path: '/listing/relisted',
            // },
        ],
    },
    {
        label: 'User',
        path: 'users',
        type: 'multiple',
        subLinks: [
            {
                label: 'All ',
                path: '/users/all',
            },
            {
                label: 'Active',
                path: '/users/active',
            },
            {
                label: 'Unverified',
                path: '/users/unverified',
            },
            {
                label: 'Deactivated',
                path: '/users/deactivate',
            },
        ],
    },
    {
        label: 'Employee',
        path: 'employees',
        type: 'multiple',
        subLinks: [
            {
                label: 'All',
                path: '/employees/all',
            },
        ],
    },
    {
        label: 'Communications',
        path: 'communications',
        type: 'multiple',
        subLinks: [
            {
                label: 'Email Template',
                path: '/communications/email',
            },
            {
                label: 'SMS Template',
                path: '/communications/sms',
            },
        ],
    },
    {
        label: 'Logs',
        path: 'logs',
        type: 'multiple',
        subLinks: [
            {
                label: 'Email',
                path: '/logs/emailLogs',
            },
            {
                label: 'SMS',
                path: '/logs/smsLogs',
            },
        ],
    },
    {
        label: 'Transactions',
        path: 'transaction',
        type: 'multiple',
        subLinks: [
            {
                label: 'Payment',
                path: '/transaction/payment',
            },
        ],
    },
    {
        label: 'Invoice',
        path: 'invoice',
        type: 'multiple',
        subLinks: [
            {
                label: 'Unpaid',
                path: '/invoice/unpaid',
            },
            {
                label: 'Paid',
                path: '/invoice/paid',
            },
        ],
    },
    // {
    //     label: 'Wallet',
    //     path: '/wallet',
    //     type: 'single',
    // },
    // {
    //     label: 'Bid Deposit',
    //     path: '/bid_deposit',
    //     type: 'single',
    // },
    {
        label: 'Report',
        path: '/report',
        type: 'single',
    },
    // {
    //     label: 'Video Auction',
    //     path: 'videoAuction',
    //     type: 'multiple',
    //     subLinks: [
    //         {
    //             label: 'Broadcast',
    //             path: '/videoAuction/broadcast',
    //         },
    //         {
    //             label: 'Live Auctions',
    //             path: '/videoAuction/live_auction',
    //         },
    //     ],
    // },
    // {
    //     label: 'Language Manager',
    //     path: '/languageManager',
    //     type: 'single',
    // },
    {
        label: 'Settings',
        path: 'settings',
        type: 'multiple',
        subLinks: [
            {
                label: 'Static Pages',
                path: '/settings/static',
            },
            {
                label: 'Brand',
                path: '/settings/category',
            },
            {
                label: 'Condition',
                path: '/settings/condition',
            },
            {
                label: 'Body Type',
                path: '/settings/bodytype',
            },
            {
                label: 'Location',
                path: '/settings/location',
            },
            {
                label: 'General Settings',
                path: '/settings/general',
            },
            {
                label: 'Bid Increments',
                path: '/settings/bid_increments',
            },
        ],
    },
]

export const ALL_PAGINATION = [
    {
        value: '20',
        show: '20 Results per page',
    },
    {
        value: '40',
        show: '40 Results per page',
    },
    {
        value: '60',
        show: '60 Results per page',
    },
]
