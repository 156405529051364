import React, { useContext, useState, useEffect } from 'react'

import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../product/components/atoms/SecondaryButton'
import FullScreenPopup from '../../product/components/organisms/FullScreenPopup'
import ProductContext from '../../product/context/product/productContext'
import CustomCommonContext from '../../custom/context/common/commonContext'
import ProductCommonContext from '../../product/context/common/commonContext'
import UserContext from '../../product/context/user/userContext'

import {
    mapData,
    dateTimeFormatFunction,
    handleRedirectInternal,
} from '../../product/common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'

const BulkListing = (props) => {
    const { bulkProjectAction } = useContext(ProductContext)
    const [isLoading, setIsLoading] = useState(false)
    const { resetDropZone } = useContext(ProductCommonContext)
    const [reload, setReload] = useState(false)

    const { allCategory, allBusinessCategory, allLocations, allCondition } =
        useContext(CustomCommonContext)

    const { getAllUsers, all_users } = useContext(UserContext)

    const data = props.data
    const toggleFullScreenPopup = props.function

    const validationArray = Yup.object({
        categoryTypeId: Yup.string(),
        subCategoryTypeId: Yup.string(),
        uploadFiles: Yup.array().required('Required!'),
    })

    const formik = useFormik({
        initialValues: {
            id: 0,
            categoryTypeId: '',
            subCategoryTypeId: '',
            uploadFiles: [],
            business_categoryid: [],
            conditionTypeId: '',
        },
        validateOnBlur: false,
        validationSchema: validationArray,
        onSubmit: (values) => {
            setIsLoading(true)
            bulkProjectAction(values)
        },
    })
    useEffect(() => {
        return () => {
            setIsLoading(false)
        }
    }, [])
    const formSubmit = (data) => {
        formik.values.market_status = data
        formik.submitForm().then(setIsLoading(false)).catch(setIsLoading(false))
    }

    useEffect(() => {
        if (formik.values.business_categoryid) {
            getAllUsers({
                email: '',
                first_name: '',
                last_name: '',
                page: 1,
                limit: 1000,
                order: '',
                orderby: '',
                filters: {
                    action: {
                        value: 'active',
                        type: 'in',
                        field: 'u.status',
                    },
                    business_category: {
                        value: formik.values.business_categoryid
                            ? formik.values.business_categoryid
                            : [],
                        type: 'array',
                        field: 'cu.business_category',
                    },
                },

                business_categoryid: formik.values.business_categoryid,
            })
        }
    }, [formik.values.business_categoryid])

    const downLoadSample = () => window.open(`${global.site_url}/samples/products.csv`)

    const productListing = [
        {
            type: 'uploadDropZone',
            formik: formik,
            name: 'uploadFiles',
            titleText: 'Upload CSV document *',
            class: 'col-12',
            folder: 'temp',
            multiple: false,
            accept: '.csv',
        },
        {
            label: 'Category',
            placeholder: 'Select category',
            type: 'select',
            class: 'col-sm-6 col-12',
            options: allCategory
                .filter((maincat) => maincat.active === 1 && maincat.level === 0)
                .map((maincat) => {
                    let categoryChanged = {}
                    categoryChanged.show = maincat.description
                    categoryChanged.value = maincat.id
                    return categoryChanged
                }),
            defaultOptionValue: 'Select a Category',
            name: 'categoryTypeId',
            formik: formik,
        },
        {
            label: 'Sub-Category',
            placeholder: 'Select sub-category',
            type: 'select',
            class: 'col-sm-6 col-12',
            options: allCategory
                .filter((subcat) =>
                    subcat.active === 1 && formik.values.categoryTypeId.length
                        ? formik.values.categoryTypeId.includes(subcat.level_id)
                        : true && subcat.level === 1,
                )
                .map((subcat) => {
                    let subCategoryChanged = {}
                    subCategoryChanged.show = subcat.description
                    subCategoryChanged.value = subcat.id
                    return subCategoryChanged
                }),
            defaultOptionValue: 'Select a Sub Category',
            name: 'subCategoryTypeId',
            formik: formik,
        },
        {
            label: 'Condition',
            placeholder: 'Select condition',
            type: 'select',
            class: 'col-sm-6 col-12',
            options: allCondition
                .filter((value) => value.active === 1)
                .map((location) => {
                    let conditionChanged = {}
                    conditionChanged.show = location.description
                    conditionChanged.value = location.id
                    return conditionChanged
                }),
            defaultOptionValue: 'Select a Condition',
            name: 'conditionTypeId',
            formik: formik,
        },
        {
            label: 'Business Category',
            placeholder: 'Select condition',
            type: 'multiselect',
            class: 'col-sm-6 col-12',
            options: allBusinessCategory
                .filter((value) => value.active === 1)
                .map((user) => {
                    let userChanged = {}
                    userChanged.show = user.description
                    userChanged.value = user.id
                    return userChanged
                }),
            defaultOptionValue: 'Select a Business Category',
            name: 'business_categoryid',
            formik: formik,
        },
    ]

    return (
        <FullScreenPopup
            modaltitle={`ADD NEW BULK LISTING`}
            open={data.popup}
            handleClose={() => toggleFullScreenPopup(false)}
        >
            <div className="addBulkUpload">
                <div className="fspBody">
                    <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                        <div className="row">
                            <div className="col-md-5 col-12 mb-4">
                                <h4 className="fspTitle">Bulk Upload Instructions</h4>
                                <ul>
                                    <li>Download the sample file.</li>
                                    <li>
                                        Don&apos;t edit the header names . Please give the
                                        appropriate values for each fields.
                                    </li>
                                    <li>
                                        You have to give proper categoryTypeId.
                                        <ol>
                                            If you want to add the additional categories please
                                            mention the IDs of those in comma separated. Ex : 2,3,4
                                            .
                                        </ol>
                                    </li>
                                    <li>
                                        You have to give proper subCategoryTypeId.
                                        <ol>
                                            If you want to add the additional sub categories please
                                            mention the IDs of those in comma separated. Ex : 2,3,4
                                            .
                                        </ol>
                                    </li>
                                    <li>You have to give proper business_categoryid.</li>
                                    <li>You have to give proper conditionTypeId.</li>
                                    <li>
                                        Below are the fields you can enable and disable them by
                                        giving 0 or 1 , that means it should have 0 or 1
                                        <ol>auction, buynow.</ol>
                                    </li>
                                    <li>
                                        Below are the fields required.
                                        <ol>
                                            <li>sprice - must be less than rprice</li>
                                            <li>
                                                rprice - must be greater sprice and less than bprice
                                            </li>
                                            <li>bprice - must be greater or equal rprice</li>
                                        </ol>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-7 col-12">
                                <div className="row">
                                    <div className="col-12">
                                        <h4 className="fspTitle">Upload CSV document</h4>
                                    </div>
                                    {Object.values(mapData(productListing))}
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <h5 className="fspSubTitle">Review</h5>
                                        <table className="table text-left">
                                            <tbody>
                                                <tr>
                                                    <th scope="row">Category Type Id:</th>
                                                    <td className="text-right">
                                                        {formik.values.categoryTypeId
                                                            ? formik.values.categoryTypeId
                                                            : 'N/A'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Sub-category Type Id:</th>
                                                    <td className="text-right">
                                                        {formik.values.subCategoryTypeId
                                                            ? formik.values.subCategoryTypeId
                                                            : 'N/A'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Condition Type Id:</th>
                                                    <td className="text-right">
                                                        {formik.values.conditionTypeId
                                                            ? formik.values.conditionTypeId
                                                            : 'N/A'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Business category Type Id:</th>
                                                    <td className="text-right">
                                                        {formik.values.business_categoryid.toString()
                                                            ? formik.values.business_categoryid.toString()
                                                            : 'N/A'}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="actionButton d-flex justify-content-center align-items-center flex-wrap">
                                    <SecondaryButton
                                        type="button"
                                        onClick={downLoadSample}
                                        label="Download Sample"
                                    />
                                    <PrimaryButton
                                        onClick={() => {
                                            formSubmit('draft')
                                        }}
                                        type="button"
                                        label="Submit"
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </FullScreenPopup>
    )
}

export default BulkListing
