import React, { useReducer } from 'react'
import CommunicationContext from './communicationContext'
import CommuncationReducer from './communicationReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import {
    GET_ALL_TEMPLATES,
    GET_SINGLE_TEMPLATE,
    GET_ALL_STATIC_PAGES,
    GET_SINGLE_STATIC_PAGE,
    GET_ALL_FOOTER_LINKS,
    GET_SINGLE_FOOTER_LINK,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
} from './communicationTypes'

const CommunicationState = (props) => {
    const initialState = {
        all_templates: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        single_template: {
            record: {},
        },
        static_pages: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        single_static_page: {
            record: {},
        },
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(CommuncationReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getAllSearchTemplates = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'templates', formData, '', 'communication'),
            ])
            const from = 'Communicationtemplates'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_TEMPLATES,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getSingleTemplate = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getSingleTemplate', formData, '', 'communication'),
            ])
            const from = 'Communicationtemplates'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_SINGLE_TEMPLATE,
                    payload: {
                        record: res.data.data.responseData.item
                            ? res.data.data.responseData.item
                            : {},
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const templateAction = async (formData) => {
        try {
            const from = 'Communicationtemplates'
            const [res] = await Promise.all([
                apiCall('post', 'templateAction', formData, '', 'communication'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllStaticPages = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'staticPages', formData, '', 'communication'),
            ])
            const from = 'CommunicationStaticPages'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_STATIC_PAGES,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllFooterLinks = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'footerlinks', formData, '', 'communication'),
            ])
            const from = 'CommunicationFooterlink'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_FOOTER_LINKS,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getSingleStaticPage = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getSingleStaticPages', formData, '', 'communication'),
            ])
            const from = 'CommunicationStaticPages'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_SINGLE_STATIC_PAGE,
                    payload: {
                        record: res.data.data.responseData.item
                            ? res.data.data.responseData.item
                            : {},
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getSingleFooterLink = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getSingleFooterLink', formData, '', 'communication'),
            ])
            const from = 'CommunicationFooterLink'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_SINGLE_FOOTER_LINK,
                    payload: {
                        record: res.data.data.responseData.item
                            ? res.data.data.responseData.item
                            : {},
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const staticPageAction = async (formData) => {
        try {
            const from = 'CommunicationStaticPages'
            const [res] = await Promise.all([
                apiCall('post', 'staticPagesAction', formData, '', 'communication'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const footerLinkAction = async (formData) => {
        try {
            const from = 'CommunicationFooterLink'
            const [res] = await Promise.all([
                apiCall('post', 'footerLinkAction', formData, '', 'communication'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <CommunicationContext.Provider
            value={{
                all_templates: state.all_templates,
                single_template: state.single_template,
                responseStatus: state.responseStatus,
                single_static_page: state.single_static_page,
                static_pages: state.static_pages,
                footer_links: state.footer_links,
                single_footer_link: state.single_footer_link,
                clearResponse,
                getAllSearchTemplates,
                getSingleTemplate,
                templateAction,
                getAllStaticPages,
                getSingleStaticPage,
                staticPageAction,
                getAllFooterLinks,
                footerLinkAction,
                getSingleFooterLink,
            }}
        >
            {props.children}
        </CommunicationContext.Provider>
    )
}

export default CommunicationState
