import React, { useContext, useState, useEffect } from 'react'

import FullScreenPopup from '../FullScreenPopup'
import { useFormik } from 'formik'
import { Button } from '@material-ui/core'
import CustomTable from '../../molecules/CustomTable'
import PrimaryButton from '../../atoms/PrimaryButton'
import CustomDialog from '../../organisms/Dialog'
import ProductContext from '../../../context/product/productContext'
import BidContext from '../../../context/bid/bidContext'
import CommonContext from '../../../context/common/commonContext'
import ReportContext from '../../../context/report/reportContext'

const BidHistory = () => {
    const productContext = useContext(ProductContext)
    const bidContext = useContext(BidContext)
    const {
        search_allbidhistory,
        getAllBidHistory,
        clearResponse: clearAllBidHistory,
    } = productContext
    const { bidCancel, responseStatus: responseStatusBid } = bidContext

    const [changeStatus, setChangeStatus] = React.useState(false)
    const changeChangeStatus = () => {
        setChangeStatus(!changeStatus)
    }

    const commonContext = useContext(CommonContext)
    const { bidHistoryValue, clearBidHistoryValue } = commonContext

    const { getAllExportDownload } = useContext(ReportContext)

    const [tableBody, setTableBody] = useState([])
    const [data, setData] = useState({
        popup: false,
        status: 'new',
        id: 0,
    })

    const formikData = useFormik({
        initialValues: {
            page: 1,
            limit: 20,
            order: '',
            orderby: '',
            get_proxy: false,
            declined: false,
            delete_bid: false,
            filters: {
                project_id: {
                    value: 0,
                    type: 'in',
                    field: 'b.project_id',
                },
                user_id: {
                    value: '',
                    type: 'in',
                    field: 'b.user_id',
                },
            },
        },
        onSubmit: (values) => {
            getCreditHistory(values)
        },
    })

    useEffect(() => {
        if (bidHistoryValue) {
            if (bidHistoryValue.id) {
                console.log('bidHistoryValue', bidHistoryValue)
                formikData.values.filters.project_id.value = bidHistoryValue.id
                formikData.values.get_proxy = bidHistoryValue.proxy
                if (bidHistoryValue.declined) {
                    formikData.values.declined = bidHistoryValue.declined
                }
                if (bidHistoryValue.delete_bid) {
                    formikData.values.delete_bid = bidHistoryValue.delete_bid
                }
                if (bidHistoryValue.user_id) {
                    formikData.values.filters.user_id.value = bidHistoryValue.user_id
                }
            } else {
                formikData.values.filters.project_id.value = bidHistoryValue
            }
            setData({ popup: true, id: bidHistoryValue })
            getAllBidHistory(formikData.values)
            clearBidHistoryValue()
            console.log(bidHistoryValue, 'bidHistoryValue')
        }
        return () => {
            clearBidHistoryValue()
        }
    }, [bidHistoryValue])

    useEffect(() => {
        if (search_allbidhistory) {
            setTableBody(search_allbidhistory.records.length ? search_allbidhistory.records : [])
        }
    }, [search_allbidhistory])

    const tableValues = [
        {
            field: 'created_at',
            type: 'datetime',
            firstChild: true,
            sort: true,
            disablePadding: false,
            label: 'Date',
        },
        {
            field: 'user_id',
            type: '',
            firstChild: false,
            sort: false,
            disablePadding: false,
            label: 'User ID',
        },
        {
            field: 'users_first_name,users_last_name',
            type: '',
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'User Name',
        },
        {
            field: 'users_email',
            type: '',
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'User Email',
        },
    ]

    if (formikData.values.declined) {
        tableValues.push({
            field: 'bidtext',
            condition: true,
            conditionValue: {
                formula: {
                    key1: 'declined',
                    key2: 1,
                    notation: '===',
                },
                true: {
                    type: 'text',
                    value: 'Declined',
                },
                false: {
                    type: 'text',
                    value: 'Accepted',
                },
            },
            type: 'text',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Status',
        })
    }

    if (formikData.values.get_proxy) {
        tableValues.push({
            field: 'bidtext',
            condition: true,
            conditionValue: {
                formula: {
                    key1: 'proxytable',
                    key2: 1,
                    notation: '===',
                },
                true: {
                    type: 'text',
                    value: 'Max',
                },
                false: {
                    type: 'value',
                    value: 'bidText',
                },
            },
            type: 'text',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Type',
        })
    }
    tableValues.push({
        field: 'proposed_amount',
        type: 'price',
        sort: true,
        firstChild: false,
        disablePadding: false,
        label: 'Amount',
    })

    const formikSelection = useFormik({
        initialValues: {
            action: '',
            id: '',
            pid: '',
        },
        onSubmit: (values) => {
            if (values.action === 'delete') {
                // setIsLoading(true)
                bidCancel(values)
            }
        },
    })

    const onClickEdit = (id, secondaryID, type) => {
        formikSelection.values.action = type
        formikSelection.values.id = id
        formikSelection.values.pid = formikData.values.filters.project_id.value
        if (type === 'delete') {
            setChangeStatus(true)
        }
    }

    if (formikData.values.delete_bid) {
        tableValues.push({
            field: 'action',
            type: 'button',
            clickType: 'delete',
            onclick: onClickEdit,
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Action',
            tooltipTitle: 'Delete Bid',
            buttonType: 'delete',
        })
    }

    useEffect(() => {
        if (responseStatusBid) {
            if (responseStatusBid.from === 'bidCancel') {
                if (responseStatusBid.status === 'success') {
                    changeChangeStatus()
                    getAllBidHistory(formikData.values)
                }
            }
        }
    }, [responseStatusBid])

    const tableActions = []

    return (
        data.popup && (
            <FullScreenPopup
                modaltitle={`Bid History`}
                open={data.popup === true}
                handleClose={() => setData({ ...data, popup: false })}
            >
                <div className="wallet">
                    <div className="fspBody">
                        <Button
                            onClick={() =>
                                getAllExportDownload({
                                    action: 'export',
                                    order: 'asc',
                                    orderby: '',
                                    search: `b.project_id=${formikData.values.filters.project_id.value}`,
                                    report: `bid_history`,
                                })
                            }
                        >
                            {' '}
                            <span className="material-icons">get_app</span>Export All Data
                        </Button>
                        <CustomTable
                            formik={formikData.values}
                            tableBody={tableBody}
                            tableValues={tableValues}
                            tableActions={tableActions}
                            allData={search_allbidhistory}
                            changeTableFunction={getAllBidHistory}
                        />
                    </div>
                </div>
                <CustomDialog
                    title={'Change Bid Status'}
                    open={changeStatus}
                    function={changeChangeStatus}
                >
                    <h5>Are you sure you want to delete the bid</h5>
                    <div className="actionWrapper">
                        <Button onClick={() => changeChangeStatus()}>Cancel</Button>
                        <form onSubmit={formikSelection.handleSubmit} autoComplete="nofill">
                            <PrimaryButton type="submit" label="Confirm" />
                        </form>
                    </div>
                </CustomDialog>
            </FullScreenPopup>
        )
    )
}

export default BidHistory
