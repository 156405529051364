import React, { useReducer } from 'react'
import CommonContext from './commonContext'
import CommonReducer from './commonReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import {
    UPLOADED_IMAGE,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    GET_MAIN_DASHBOARD_DATA,
    GET_MAIN_DASHBOARD_DATA_MORE,
    BID_HISTORY_VALUE,
    CLEAR_BID_HISTORY,
    TTW_BID_HISTORY_VALUE,
    TTW_CLEAR_BID_HISTORY,
    GET_ALL_COUNTRIES,
    GET_ALL_STATES,
    GET_ALL_CITIES,
} from './commonTypes'

const CommonState = (props) => {
    const initialState = {
        uploadedFiles: [],
        mainDashboardData: {},
        mainDashboardDataMore: {},
        bidHistoryValue: 0,
        ttwBidHistoryValue: 0,
        allCountries: [],
        allStates: [],
        allCities: [],
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(CommonReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const uploadImage = async (formData, fromUpload) => {
        try {
            const from = 'productchange'
            const [res] = await Promise.all([
                apiCall('post', 'muliple_img_uploader', formData, '', 'image_uploader', true),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: UPLOADED_IMAGE,
                    payload: {
                        files: res.data.data.responseData.files,
                        from: fromUpload,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllCountries = async (formData) => {
        const from = 'getAllCountries'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, { ...formData, orderby: 'name, asc' }, '', '', true),
            ])

            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_COUNTRIES,
                    payload: {
                        records: res.data.data.responseData.records
                            ? res.data.data.responseData.records
                            : [],
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllStates = async (formData) => {
        const from = 'getAllStates'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, { ...formData, orderby: 'name, asc' }, '', '', true),
            ])

            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_STATES,
                    payload: {
                        records: res.data.data.responseData.records
                            ? res.data.data.responseData.records
                            : [],
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllCities = async (formData) => {
        const from = 'getAllCities'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, { ...formData, orderby: 'name, asc' }, '', '', true),
            ])

            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_CITIES,
                    payload: {
                        records: res.data.data.responseData.records
                            ? res.data.data.responseData.records
                            : [],
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const resetDropZone = (fromUpload) => {
        dispatch({
            type: UPLOADED_IMAGE,
            payload: {
                files: [],
                from: fromUpload,
            },
        })
    }

    const deleteImage = (file, fromUpload) => {
        resetDropZone(fromUpload)
        //Implement the removal of image api
    }

    const getMainDashboardData = async () => {
        try {
            const [res] = await Promise.all([
                apiCall('post', '', {}, '', 'getMainDashboardData', false),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_MAIN_DASHBOARD_DATA,
                    payload: {
                        mainDashboardData: res.data.data.mainDashboardData,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getMainDashboardDataMore = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', '', formData, '', 'getMainDashboardDataMore', false),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_MAIN_DASHBOARD_DATA_MORE,
                    payload: {
                        mainDashboardDataMore: res.data.data.mainDashboardData,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const setBidHistoryValue = async (data) => {
        dispatch({
            type: BID_HISTORY_VALUE,
            payload: data,
        })
    }

    const clearBidHistoryValue = () =>
        dispatch({
            type: CLEAR_BID_HISTORY,
        })

    const setTtwBidHistoryValue = async (data) => {
        dispatch({
            type: TTW_BID_HISTORY_VALUE,
            payload: data,
        })
    }

    const clearTtwBidHistoryValue = () =>
        dispatch({
            type: TTW_CLEAR_BID_HISTORY,
        })

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <CommonContext.Provider
            value={{
                uploadedFiles: state.uploadedFiles,
                responseStatus: state.responseStatus,
                mainDashboardData: state.mainDashboardData,
                mainDashboardDataMore: state.mainDashboardDataMore,
                bidHistoryValue: state.bidHistoryValue,
                ttwBidHistoryValue: state.ttwBidHistoryValue,
                search_allttwbidhistory: state.search_allttwbidhistory,
                allCountries: state.allCountries,
                allStates: state.allStates,
                allCities: state.allCities,
                uploadImage,
                setBidHistoryValue,
                clearBidHistoryValue,
                resetDropZone,
                deleteImage,
                getAllCountries,
                getAllStates,
                getAllCities,
                getMainDashboardData,
                getMainDashboardDataMore,
                setTtwBidHistoryValue,
                clearTtwBidHistoryValue,
            }}
        >
            {props.children}
        </CommonContext.Provider>
    )
}

export default CommonState
