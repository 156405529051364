import React, { useContext, useState, useEffect, useRef } from 'react'
import Layout from '../../product/components/templates/Layout'
import CustomTable from '../../product/components/molecules/CustomTable'
import CustomSearch from '../../product/components/molecules/CustomSearch'
import { Button } from '@material-ui/core'
import { mapData } from '../../product/common/components'
import { useFormik } from 'formik'
import CustomCommonContext from '../../custom/context/common/commonContext'
import CustomDialog from '../../product/components/organisms/Dialog'

import * as Yup from 'yup'
import FullScreenPopup from '../../product/components/organisms/FullScreenPopup'
import SecondaryButton from '../../product/components/atoms/SecondaryButton'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import SettingContext from '../../product/context/setting/settingContext'
import AlertContext from '../../product/context/alert/alertContext'
import UserCommonContext from '../../product/context/userCommon/commonContext'

const Location = () => {
    const settingcontext = useContext(SettingContext)
    const usercommoncontext = useContext(UserCommonContext)
    const alertContext = useContext(AlertContext)

    const { setAlert } = alertContext

    const { allLocations, getGlobalVariable } = useContext(CustomCommonContext)
    const [isLoading, setIsLoading] = useState(true)

    const {
        searchTableAction,
        responseStatus: responseStatusSetting,
        clearResponse: clearResponseCommuncation,
    } = settingcontext

    const [changeStatus, setChangeStatus] = useState(false)
    const changeChangeStatus = () => {
        setChangeStatus(!changeStatus)
    }

    const [manage, setManage] = React.useState({
        popup: false,
        status: 'new',
        id: 0,
    })

    const toggleFullScreenPopup = (popup, status, id) => {
        if (popup) {
            setManage({ popup, status, id })
        } else {
            setManage({ popup, status: 'new', id: 0 })
        }
    }

    const [tableBody, setTableBody] = useState([])
    const formikSelection = useFormik({
        initialValues: {
            action: '',
            active: 0,
            id: 0,
            table: 'locations',
        },
        onSubmit: (values) => {
            const dataValue = values
            if (
                dataValue.action === 'active' ||
                dataValue.action === 'deactive' ||
                dataValue.action === 'delete'
            ) {
                dataValue.active =
                    dataValue.action === 'delete' ? 2 : dataValue.action === 'active' ? 1 : 0
                delete dataValue.action
                searchTableAction(values)
            }
        },
    })
    const validationArray = Yup.object({
        name: Yup.string().required('Required!'),
        address: Yup.string().required('Required!'),
        city: Yup.string().required('Required!'),
        state: Yup.string().required('Required!'),
        zipCode: Yup.number().positive().integer().required('Required!'),
        weeklyHoursStart: Yup.string().required('Required!'),
        weeklyHoursEnd: Yup.string().required('Required!'),
        saturdayHoursStart: Yup.string().required('Required!'),
        saturdayHoursEnd: Yup.string().required('Required!'),
        sundayHoursStart: Yup.string().required('Required!'),
        sundayHoursEnd: Yup.string().required('Required!'),
    })

    const formikView = useFormik({
        initialValues: {
            page: 1,
            limit: 20,
        },
        onSubmit: (values) => {},
    })

    const formik = useFormik({
        initialValues: {
            id: 0,
            name: '',
            address: '',
            city: '',
            state: '',
            zipCode: '',
            weeklyHoursStart: '',
            weeklyHoursEnd: '',
            saturdayHoursStart: '',
            saturdayHoursEnd: '',
            sundayHoursStart: '',
            sundayHoursEnd: '',
            table: 'locations',
            active: 1,
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            console.log('+++++++++++++', values)
            values.weeklyHours = values.weeklyHoursStart + ' - ' + values.weeklyHoursEnd
            values.saturdayHours = values.saturdayHoursStart + ' - ' + values.saturdayHoursEnd
            values.sundayHours = values.sundayHoursStart + ' - ' + values.sundayHoursEnd
            searchTableAction(values)
        },
    })

    const changePagination = () => {}

    useEffect(() => {
        if (allLocations) {
            setTableBody(allLocations.length ? allLocations : [])
            formikView.values.limit = allLocations.length
        }
        setIsLoading(false)
    }, [allLocations])

    useEffect(() => {
        if (responseStatusSetting) {
            if (
                responseStatusSetting.from === 'CommunicationSearchTable' &&
                responseStatusSetting.status === 'success'
            ) {
                setChangeStatus(false)
                toggleFullScreenPopup(false)
                setTableBody([])
                getGlobalVariable()
            }
        }
    }, [responseStatusSetting])

    const onClickEdit = (id, secondaryID, type) => {
        toggleFullScreenPopup(true, 'edit', id)
    }

    useEffect(() => {
        if (manage.status === 'edit') {
            formik.setFieldValue('id', manage.id)
            const template = allLocations.filter((cat) => cat.id === manage.id)
            formik.setValues({
                ...formik.values,
                ...template[0],
                ...{
                    weeklyHoursStart: template[0].weeklyHours
                        ? template[0].weeklyHours.split(' - ')[0]
                        : '',
                    weeklyHoursEnd: template[0].weeklyHours
                        ? template[0].weeklyHours.split(' - ')[1]
                        : '',
                    saturdayHoursStart: template[0].saturdayHours
                        ? template[0].saturdayHours.split(' - ')[0]
                        : '',
                    saturdayHoursEnd: template[0].saturdayHours
                        ? template[0].saturdayHours.split(' - ')[1]
                        : '',
                    sundayHoursStart: template[0].sundayHours
                        ? template[0].sundayHours.split(' - ')[0]
                        : '',
                    sundayHoursEnd: template[0].sundayHours
                        ? template[0].sundayHours.split(' - ')[1]
                        : '',
                },
            })
        } else {
            resetFrom()
        }
    }, [manage])

    const resetFrom = () => {
        formik.setValues({
            id: 0,
            name: '',
            address: '',
            city: '',
            state: '',
            zipCode: '',
            weeklyHoursStart: '',
            weeklyHoursEnd: '',
            saturdayHoursStart: '',
            saturdayHoursEnd: '',
            sundayHoursStart: '',
            sundayHoursEnd: '',
            table: 'locations',
            active: 1,
        })
    }

    const tableValues = [
        {
            field: 'id',
            type: '',
            firstChild: true,
            sort: true,
            disablePadding: false,
            label: 'ID',
        },
        {
            field: 'name',
            type: '',
            firstChild: true,
            sort: true,
            disablePadding: false,
            label: 'Name *',
        },
        {
            field: 'active',
            condition: true,
            conditionValue: {
                formula: {
                    key1: 'active',
                    key2: 1,
                    notation: '===',
                },
                true: {
                    type: 'text',
                    value: 'Active',
                },
                false: {
                    type: 'text',
                    value: 'Deactive',
                },
            },
            type: 'text',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Status',
        },
        {
            field: 'action',
            type: 'button',
            clickType: 'edit',
            onclick: onClickEdit,
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Action',
            tooltipTitle: 'Edit location',
        },
    ]
    const details = [
        {
            label: 'Name *',
            placeholder: 'Enter the Location',
            type: 'textarea',
            class: 'col-12',
            name: 'name',
            formik: formik,
        },
        {
            label: 'Address *',
            placeholder: 'Enter the Address',
            type: 'input',
            class: 'col-12',
            name: 'address',
            formik: formik,
        },
        {
            label: 'City *',
            placeholder: 'Enter the City',
            type: 'input',
            class: 'col-12',
            name: 'city',
            formik: formik,
        },
        {
            label: 'State *',
            placeholder: 'Enter the State',
            type: 'input',
            class: 'col-12',
            name: 'state',
            formik: formik,
        },
        {
            label: 'Zip *',
            placeholder: 'Enter the Zip',
            type: 'input',
            class: 'col-12',
            name: 'zipCode',
            formik: formik,
        },
        {
            label: 'Weekly Start Time',
            placeholder: 'Select Weekly Start Time',
            type: 'time',
            class: 'col-6',
            name: 'weeklyHoursStart',
            formik: formik,
        },
        {
            label: 'Weekly End Time',
            placeholder: 'Select Weekly End Time',
            type: 'time',
            class: 'col-6',
            name: 'weeklyHoursEnd',
            formik: formik,
        },
        {
            label: 'Saturday End Time',
            placeholder: 'Select Saturday End Time',
            type: 'time',
            class: 'col-6',
            name: 'saturdayHoursStart',
            formik: formik,
        },
        {
            label: 'Saturday End Time',
            placeholder: 'Select Saturday End Time',
            type: 'time',
            class: 'col-6',
            name: 'saturdayHoursEnd',
            formik: formik,
        },
        {
            label: 'Sunday End Time',
            placeholder: 'Select Sunday End Time',
            type: 'time',
            class: 'col-6',
            name: 'sundayHoursStart',
            formik: formik,
        },
        {
            label: 'Sunday End Time',
            placeholder: 'Select Sunday End Time',
            type: 'time',
            class: 'col-6',
            name: 'sundayHoursEnd',
            formik: formik,
        },
        {
            label: 'Active',
            placeholder: 'Make Active',
            type: 'switch',
            class: 'col-6',
            name: 'active',
            formik: formik,
        },
    ]

    const onSelectMultiProducts = (data, action) => {
        formikSelection.values.action = action
        formikSelection.values.id = data
        if (action === 'active' || action === 'deactive' || action === 'delete') {
            if (action === 'delete') {
                setChangeStatus(true)
            } else if (action === 'deactive') {
                setChangeStatus(true)
            } else {
                setChangeStatus(true)
            }
        }
    }

    const tableActions = []
    tableActions.push(
        {
            label: 'Move to Active',
            icon: <span className="material-icons">check_circle</span>,
            onclick: onSelectMultiProducts,
            type: 'active',
        },
        {
            label: 'Move to Deactive',
            icon: <span className="material-icons">block</span>,
            onclick: onSelectMultiProducts,
            type: 'deactive',
        },
        {
            label: 'Delete Permanently',
            icon: <span className="material-icons">cancel</span>,
            onclick: onSelectMultiProducts,
            type: 'delete',
        },
    )

    const searchInfo = [
        {
            label: 'Business location ',
            placeholder: 'Enter location',
            class: 'col-md-4 col-sm-6 col-12',
            type: 'text',
            name: 'searchterm',
            formik: formikView,
        },
    ]

    return (
        <Layout>
            <div className="dashboard staticPages">
                <CustomSearch searchInfo={searchInfo} formik={formikView} />
                <div className="d-flex justify-content-between align-items-center mt-4">
                    <Button
                        variant="outlined"
                        className="btnOutlined"
                        onClick={() => toggleFullScreenPopup(true, 'new', 0)}
                    >
                        <span className="material-icons">note_add</span>Add New Location
                    </Button>
                </div>

                <CustomTable
                    formik={formikView.values}
                    tableBody={tableBody}
                    tableValues={tableValues}
                    tableActions={tableActions}
                    allData={{
                        records: allLocations,
                        setDisp: `0-${allLocations.length}`,
                        totalRecords: allLocations.length,
                    }}
                    changeTableFunction={changePagination}
                />
            </div>
            <FullScreenPopup
                modaltitle={manage.status === 'new' ? 'Add New Location' : 'Edit Location'}
                open={manage.popup}
                handleClose={() => toggleFullScreenPopup(false, 'new', 0)}
            >
                <div className="dashboard addNewListing mt-4">
                    <div className="fspBody">
                        <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                            <div className="row">{Object.values(mapData(details))}</div>
                            <div className="actionButton d-flex justify-content-center align-items-center">
                                <SecondaryButton
                                    label="Cancel"
                                    onClick={() => toggleFullScreenPopup(false)}
                                />
                                <PrimaryButton type="submit" label="Submit" />
                            </div>
                        </form>
                    </div>
                </div>
            </FullScreenPopup>

            <CustomDialog title={'Change status'} open={changeStatus} function={changeChangeStatus}>
                <h5>
                    {formikSelection.values.action === 'delete'
                        ? 'Are you sure you want to delete'
                        : 'Are you sure you want to change the status'}
                </h5>
                <div className="actionWrapper">
                    <Button onClick={() => changeChangeStatus()}>Cancel</Button>
                    <form onSubmit={formikSelection.handleSubmit} autoComplete="nofill">
                        <PrimaryButton type="submit" label="Confirm" />
                    </form>
                </div>
            </CustomDialog>
        </Layout>
    )
}
export default Location
